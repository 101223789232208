import { useEffect, useLayoutEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { faker } from '@faker-js/faker';
import { useSelector } from 'react-redux'

// @mui
import { useTheme } from '@mui/material/styles';
import {
  Grid, Container, Typography, Table,
  Button,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Stack,
  Box,
  TextField,
  ClickAwayListener,
  InputBase
} from '@mui/material';
// components
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// sections
import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Iconify from '../components/iconify';
import {
  AppTasks,
  AppNewsUpdate,
  AppOrderTimeline,
  AppCurrentVisits,
  AppWebsiteVisits,
  AppTrafficBySite,
  AppWidgetSummary,
  AppCurrentSubject,
  AppConversionRates,
} from '../sections/@dashboard/app';

import { Api, UploadFile } from '../webservices';

// ----------------------------------------------------------------------
const tablebg = 'rgba(82, 130, 190, 0.08)';
export default function DashboardAppPage() {
  const Role = useSelector((state) => state.userData.data.role)
  const clientAccess = useSelector((state) => state.userData.data?.accessRights?.clients)

  // console.log("======ClientAccess=========", clientAccess)
  const recruiterRoles = ["recruiterHead", "recruiterManager", "recruiterTeamLead", "recruiter", "recruiterTrainee"];
  const salesRoles = ["salesHead", "salesManager", "salesTeamLead", "salesExecutive", "salesTrainee"];

  const theme = useTheme();
  const token = localStorage.getItem('token');
  // const userId = '';
  const [tab, setTab] = useState('recruitment');
  const [recTable, setRecTable] = useState([]);
  const [recRaddial, setRecRaddial] = useState();
  const [saleRaddial, setSaleRaddial] = useState();
  const [saleRaddial1, setSaleRaddial1] = useState();
  const [saleTable, setSaleTable] = useState([]);
  const [Users, setUsers] = useState([]);
  const [per, setPer] = useState({})
  const [totall, setTotall] = useState([]);
  const [allSLData, setAllSLData] = useState([]);
  const [totals, setTotalSLData] = useState([]);

  const [totallSales, setTotallSales] = useState([]);
const [clientRad, setClientRaddial] = useState([])
const [clientRadDate, setClientRaddialDate] = useState([])
// console.log("======clientRad", clientRad)
// console.log("======clientRadDate", clientRadDate)

  useEffect(() => {

    // if (recruiterRoles.includes(Role)) {
    //   setTab('recruitment');
    // } else if (salesRoles.includes(Role)) {
    //   setTab('sales');
    // } else if (Role === 'admin') {
    //   setTab('recruitment');
    // }
    getRecBar()
    getRecRadial()
    getDashboardPercentage()
    getClients()
  }, []);

  // console.log("userData ===>>>", Role)

  const [rec, setRec] = useState({
    timePeriod: 'week',
    // timePeriod: 'year',
    allUser: '',
    tempRole: '',
    teamUser: '',
    isTeam: 'all',
    userId: '',
    cid:'',
    // fromDate:"",
    // tillDate:""
    // fromDate: oneMonthAgo.toISOString(),
    // tillDate: today.toISOString()

    fromDate: '',
    tillDate: ''
  });
  const [valuee, setValuee] = useState({ fromDate: '', tillDate: '' })

  const [sal, setSal] = useState({

    fromDate: dayjs().subtract(1, 'month').toISOString(),
    tillDate: dayjs().toISOString()

  });
  
  const [client, setClient] = useState({

    fromDate: '',
    tillDate: ''

  });


  useEffect(() => {
    const currentDate = dayjs();
    const oneMonthAgo = currentDate.subtract(1, 'month');
    setValuee({
      fromDate: oneMonthAgo,
      tillDate: currentDate
    });
    setValueee({
      fromDate: oneMonthAgo,
      tillDate: currentDate
    });
    setValueeee({
      fromDate: oneMonthAgo,
      tillDate: currentDate
    });
  }, []);

  const [valueee, setValueee] = useState({ fromDate: '', tillDate: '' })
  const [valueeee, setValueeee] = useState({ fromDate: '', tillDate: '' })

  useEffect(() => {
    setTimeout(() => {
      recTableByDate();
    }, 500)

  }, [valuee.fromDate, valuee.tillDate]);

  // const [fromDate, setFromDate] = useState()
  useEffect(() => {
    // salTableByDate();
    allTableByDate()
  }, [valueee.fromDate, valueee.tillDate]);

  const [rbar, setRbar] = useState([])
  const [rbarclient, setRbarClient] = useState([])
  const [sbar, setSbar] = useState([])

  // console.log("===========rbarclient", rbarclient)
  // console.log("valueee.fromDate==>>>", valueee.fromDate)
  // console.log("valueee.tillDate==>>>", valueee.tillDate)

  // useEffect(() => {
  //   handleTab("sales")

  //   setTimeout(() => {
  //     getDashboardPercentage()
  //     handleTab("recruitment")
  //   }, 3000) 
  //   // getRecRadial()
  //   // getDashboardPercentage()
  //   // getRecBar()   
  //   // recTableByDate()

  // }, []);



  const getDashboardPercentage = async () => {
    const body = {
      "amount": "1",
      "unit": "year"
    }
    Api(`common/percentage`, 'POST', body, token)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.code === 200) {
            // console.log("ashishg---------------->>>>>", response)
            setPer(response.data);
          } else {
            toast.warn(response.data.message, +'!');
          }
        }
      })
      .catch((err) => {
        // console.log('===========', err);
        toast.error(err, +'!');
      });
  }

  const handleRole = (val) => {
    Api(`company/userbyrole/${val}`, 'GET', '', token).then((Response) => {
      if (Response.status === 200) {
        if (Response.data.code === 200) {
          if (Response.data.data.length) {
            setUsers(Response.data.data);
          } else {
            toast.warn(Response.data.message);
          }
        }
      } else {
        toast.error(Response.data.error, +'!');
      }
    });
  };
  const handleUser = () => {
    Api(`common/users/${tab === 'recruitment' ? 'RC' : 'SL'}`, 'GET', '', token).then((Response) => {
      if (Response.status === 200) {
        if (Response.data.code === 200) {
          if (Response.data.data.length) {
            setUsers(Response.data.data);
          } else {
            toast.warn(Response.data.message);
          }
        }
      } else {
        toast.error(Response.data.error, +'!');
      }
    });
  };

  const getRecBarClient = async (t, id, tp) => {
    const body = {
      "unit": tp || rec.timePeriod,
      "dataOf": t || rec.isTeam,
      "clientId": id || rec.userId || rec.cid
    }
    Api(`common/dataAndCountsByClientBar`, 'POST', body, token)
      .then((response) => {
        if (response.status === 200) {
          setRbarClient(response.data.result);
          if (response.data.code === 200) {
            // alert("ll")
            // setRbar(response.data.inRangedTotalForAllUsers);
          } else {
            toast.warn(response.data.message, +'!');
          }
        }
      })
      .catch((err) => {
        // console.log('===========', err);
        toast.error(err, +'!');
      });
  }

  const getRecRadialClient = async (t, id, tp) => {
    const body = {
      "unit": tp || rec.timePeriod,
      "dataOf": t || rec.isTeam,
      "clientId": id || rec.userId || rec.cid,
    }
    Api(`common/dataAndCountsByClientRED`, 'POST', body, token)
      .then((response) => {
        if (response.status === 200) {
          setClientRaddial(response.data.result)
          setClientRaddialDate(response.data)
          if (response.data.code === 200) {
            // setRecRaddial(response.data);
            // setRecTable(response.data.recruitement);
            // setTotall(response.data)
          } else {
            toast.warn(response.data.message, +'!');
          }
        }
      })
      .catch((err) => {
        // console.log('===========', err);
        toast.error(err, +'!');
      });
  }

  const getRecBar = async (t, id, tp) => {
    const body = {
      "unit": tp || rec.timePeriod,
      "dataOf": t || rec.isTeam,
      "userId": id || rec.userId
    }
    Api(`common/dash/rbar`, 'POST', body, token)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.code === 200) {
            setRbar(response.data.inRangedTotalForAllUsers);
          } else {
            toast.warn(response.data.message, +'!');
          }
        }
      })
      .catch((err) => {
        // console.log('===========', err);
        toast.error(err, +'!');
      });
  }

  const getRecRadial = async (t, id, tp) => {
    const body = {
      "unit": tp || rec.timePeriod,
      "dataOf": t || rec.isTeam,
      "userId": id || rec.userId,
    }
    Api(`common/dash/rec`, 'POST', body, token)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.code === 200) {
            setRecRaddial(response.data);
            setRecTable(response.data.recruitement);
            // setTotall(response.data)
          } else {
            toast.warn(response.data.message, +'!');
          }
        }
      })
      .catch((err) => {
        // console.log('===========', err);
        toast.error(err, +'!');
      });
  }

  // console.log("dateObject.fromDate===>>>", recRaddial.dateObject.fromDate);

  // console.log("total===>>>", totall);
  // console.log("totallSales====>>>", totallSales)
  // console.log("saleRaddial===>>===", saleRaddial)

  const getSaleRadial = async (t, id, tp) => {
    const body = {
      "unit": tp || rec.timePeriod,
      "dataOf": t || rec.isTeam,
      "userId": id || rec.userId,
    }
    Api(`common/dash/sl`, 'POST', body, token)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.code === 200) {
            setSaleRaddial(response.data.totalSales);
            setSaleRaddial1(response.data);
            setSaleTable(response.data.salesSideUsers);
            // setTotallSales(response.data.totalSales)
          } else {
            toast.warn(response.data.message, +'!');
          }
        }
      })
      .catch((err) => {
        // console.log('===========', err);
        toast.error(err, +'!');
      });
  }

  const handleTab = (tab) => {
    setRec({
      timePeriod: 'week',
      // timePeriod: 'year',
      allUser: 'all',
      tempRole: '',
      teamUser: '',
      isTeam: 'all',
      userId: '',
    })
    setTab(tab)
    if (tab === 'recruitment') {
      getRecRadial()
      getRecBar()
      recTableByDate()
      getDashboardPercentage();
    } else if (tab === 'sales') {
      getSaleRadial()
      // salTableByDate();
      allTableByDate();
      getSalesBar()
      getDashboardPercentage();
    } else if (tab === "re"){
      // alert("KK")
      // handleTab('re')
      // console.log("=======", tab)
      getRecBarClient()
      getRecRadialClient()
      clientTableByDate()   
    }
  }

  // useEffect(() => {
  //   handleTab("sales");

  //   if (!salesRoles.includes(Role)) {
  //     setTimeout(() => {
  //       getDashboardPercentage();
  //       handleTab("recruitment");
  //     }, 3000);
  //   } else {
  //     // setTimeout(() => {
  //     getDashboardPercentage();
  //     // }, 1000);
  //   }

  //   // Uncomment the following lines if you want to call these functions as well
  //   // getRecRadial();
  //   // getRecBar();
  //   // recTableByDate();
  // }, []);

  const [selectedDropdownValue, setSelectedDropdownValue] = useState('recruitment');

  const handleChangeDrop = (event) => {
    const selectedTab = event.target.value.toLowerCase();
    // setTab(selectedTab);
    setSelectedDropdownValue(selectedTab)
  }
  const Funcc = () => {
    const selectedTab = selectedDropdownValue.toLowerCase();
    if (selectedTab === 'recruitment') {
      setTab(selectedTab);
      getRecRadial();
      getRecBar();
      recTableByDate();
      getDashboardPercentage();
    } else if (selectedTab === 'sales') {
      setTab(selectedTab);
      getSaleRadial();
      getSalesBar();
      salTableByDate();
      getDashboardPercentage();
    // } else if (selectedTab === 'item orders') {
    } else if (selectedTab === 'clients') {
      // handleTab('re')
      setTab("re");
      // getDashboardPercentage();
      getRecBarClient()
      getRecRadialClient()
      clientTableByDate()
    }
  }

  // console.log("selectedTab===>>", selectedTab)


  const getAll = async (val, id, tp) => {
    if (tab === 'recruitment') {
      getRecRadial(val, id, tp)
      getRecBar(val, id, tp)
      getDashboardPercentage();
    } else if (tab === 'sales') {
      getSaleRadial(val, id, tp)
      getSalesBar(val, id, tp)
      getDashboardPercentage();
    } else if (tab === "re"){
      getRecBarClient(val, id, tp)
      getRecRadialClient(val, id, tp)
    }
  }

  const getSalesBar = async (t, id, tp) => {
    const body = {
      "unit": tp || rec.timePeriod,
      "dataOf": t || rec.isTeam,
      "userId": id || rec.userId
    }
    Api(`common/dash/sbar`, 'POST', body, token)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.code === 200) {
            setSbar(response.data.inRangedTotalForAllUsers);
          } else {
            toast.warn(response.data.message, +'!');
          }
        }
      })
      .catch((err) => {
        // console.log('===========', err);
        toast.error(err, +'!');
      });
  }

  const [tableByDate, setTableByDate] = useState([])
  const [tableByDate1, setTableByDate1] = useState([])

  const [tableByDateClient, setTableByDateClient] = useState([])
  const [tableByDateClientTotal, setTableByDateClientTotal] = useState([])

  // console.log("---------tableByDateClient", tableByDateClient)
  // console.log("---------tableByDateClientTotal", tableByDateClientTotal)

  // console.log("tableByDate===>>>>+++", tableByDate)
  // console.log("fromDate===>>>>+++", rec.fromDate)
  // console.log("tillDate===>>>>+++", rec.tillDate)

  // console.log("tillDate+++", new Date(rec.tillDate).toLocaleDateString("en-US",
  //   { month: "2-digit", day: "2-digit", year: "numeric" }));

  // const recDate = new Date(rec.tillDate).toLocaleDateString("en-US",
  //   { month: "2-digit", day: "2-digit", year: "numeric" })

  // console.log("fromDate+++", new Date(rec.fromDate).toLocaleDateString("en-US",
  //   { month: "2-digit", day: "2-digit", year: "numeric" }));

  // const salDate = new Date(rec.fromDate).toLocaleDateString("en-US",
  //   { month: "2-digit", day: "2-digit", year: "numeric" })

  useEffect(() => {
    if (rec.userId) {
      getAll(rec.isTeam, rec.userId);
      recTableByDate(rec.isTeam, rec.userId);
      salTableByDate(rec.isTeam, rec.userId);
      clientTableByDate("", rec.userId);
      // salTableByDate(rec.isTeam, rec.userId);
    } else {
      clientTableByDate("", rec.cid);
      getRecBarClient()
      getRecRadialClient()
    }
      // allTableByDate();
    
  }, [rec.userId, rec.cid]);

  // const handleChange = (e) => {
  //   // alert(e.target.value)
  //   setRec({ ...rec, userId: e.target.value }); 
  // };

  const handleChange = (e) => {
    // alert(e.target.value)
    // setRec({ ...rec, userId: e.target.value }); 
    if (tab !== "re") {
      setRec({ ...rec, userId: e.target.value });  
    } else {
      setRec({ ...rec, cid: e.target.value }); 
    }

  };

  const recTableByDate = async (t, id) => {

    const body = {
      // "dataOf": "all",
      // "userId": "",
      "dataOf": t || rec.isTeam,
      "userId": id || rec.userId,
      "fromDate": valuee.fromDate || rec.fromDate || '',
      "tillDate": valuee.tillDate || rec.tillDate || ''

    };

    Api(`common/dash/rec/table`, 'POST', body, token)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.code === 200) {
            setTableByDate(response.data.RecruiterTable);
            setTotall(response.data)
          } else {
            // toast.warn(response.data.message + '!');
          }
        }
      })
      .catch((err) => {
        // console.log('===========', err);
        // toast.error(err + '!');
      });
  };

  const allTableByDate = async (t, id) => {
    // console.log("all Users=======================>>", id);
    const body = {
      // "dataOf": "all",
      // "userId": "",
      // "dataOf": "All",
      // "userId": id || rec.userId,
      "fromDate": valueee.fromDate || sal.fromDate || '',
      "tillDate": valueee.tillDate || sal.tillDate || ''

    };

    Api(`common/dash/sl/table/all`, 'POST', body, token)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.code === 200) {
            setTableByDate([]);
            setAllSLData(response.data.data)
            const totals = {
              totalNew: 0,
              totalPipeline: 0,
              totalMeetings: 0,
              totalConverted: 0,
              totalPassiveclient: 0
            }
            response.data.data.forEach((item) => {
              const { new: newStatus, pipeline: newPipeline, meetings: newMeetings, converted: newConverted, passiveclient: newPassiveclient } = item.statuses;

              totals.totalNew += newStatus;
              totals.totalPipeline += newPipeline;
              totals.totalMeetings += newMeetings;
              totals.totalConverted += newConverted;
              totals.totalPassiveclient += newPassiveclient;

              // console.log("data=========>>", newStatus);
            });
            setTotalSLData(totals)

            // console.log("Totals object: ", totals);
            // setTotall(response.data)
          } else {
            // toast.warn(response.data.message + '!');
          }
        }
      })
      .catch((err) => {
        // console.log('===========', err);
        // toast.error(err + '!');
      });
  };

  useEffect(()=>{
    clientTableByDate()

  }, [client.fromDate || valueeee.fromDate, client.tillDate || valueeee.tillDate])

  const clientTableByDate = async (t, id) => {
    const body = {
      // "dataOf": "all",
      // "userId": "",
      
      "dataOf": t || rec.isTeam,
      "clientId": id || rec.userId || rec.cid,
      "fromDate": valueeee.fromDate || client.fromDate || '',
      "tillDate": valueeee.tillDate || client.tillDate || ''
    };
    Api(`common/dataAndCountsByClientTable`, 'POST', body, token)
      .then((response) => {
        if (response.status === 200) {
          setTableByDateClient(response.data.result)
          setTableByDateClientTotal(response.data.totalCountObject)
          if (response.data.code === 200) {
            // setTableByDate1(response.data.salesTable);
            // setTotallSales(response.data.totalSales)
          } else {
            // toast.warn(response.data.message + '!');
          }
        }
      })
      .catch((err) => {
        // console.log('===========', err);
        // toast.error(err + '!');
      });
  };

  // console.log(" >>>>>>>>>> rec.isTeam >>>>>>>>>>", rec.isTeam)
  
  const salTableByDate = async (t, id) => {
    const body = {
      // "dataOf": "all",
      // "userId": "",
      "dataOf": t || rec.isTeam,
      "userId": id || rec.userId,
      "fromDate": valueee.fromDate || sal.fromDate || '',
      "tillDate": valueee.tillDate || sal.tillDate || ''
    };
    Api(`common/dash/sl/table`, 'POST', body, token)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.code === 200) {
            setTableByDate1(response.data.salesTable);
            setTotallSales(response.data.totalSales)
          } else {
            // toast.warn(response.data.message + '!');
          }
        }
      })
      .catch((err) => {
        // console.log('===========', err);
        // toast.error(err + '!');
      });
  };

const [clientList, setClientList] = useState([])

// console.log("=================clientList", clientList)

const getClients = () => {
  const token1 = localStorage.getItem('token');
  
  Api('common/getClientList', 'GET', '', token1).then((response) => {

    if (response.status === 200) {
      // alert("jj")
      setClientList(response.data.result);
      if (response.result.length) {
        setClientList(response.result);
      } else {
        setClientList([]);
      }
    } else {
      // toast.warn(Response.error + '!');
    }
  }).catch((error) => {
    // toast.error('Failed to fetch clients! ' + error.message);
  });
};


  // const getClients = () => {
  //   const body = {
  //     status: "",
  //     page: 1,
  //     limit: '35',
  //   };
  //   const token1 = localStorage.getItem('token');
  //   Api('company/direct_converted', 'POST', body, token1).then((Response) => {
  //     if (Response.status === 200) {
  //       if (Response.data.code === 200) {
  //         if (Response.data.data.length) {
  //           setClientList(Response.data.data);
  //         } else {
  //           // setClientList([])
  //         }
  //       }
  //     } else {
  //       toast.warn(Response.data.error, +'!');
  //     }
  //   });
  // };

  const handleDateChange = (date, field) => {
    const isoString = dayjs(date).format('YYYY-MM-DDTHH:mm:ss[Z]');
    // const isoString = dayjs(date).toISOString();
    setValuee((prevRec) => ({
      ...prevRec,
      [field]: isoString
    }));
    // console.log("isoString====>>>>", isoString)
  };

  const handleDateChange1 = (date, field) => {
    const isoString = dayjs(date).format('YYYY-MM-DDTHH:mm:ss[Z]');
    setValueee((prevRec) => ({
      ...prevRec,
      [field]: isoString
    }));

  };

  const handleDateChange2 = (date, field) => {
    const isoString = dayjs(date).format('YYYY-MM-DDTHH:mm:ss[Z]');
    setValueeee((prevRec) => ({
      ...prevRec,
      [field]: isoString
    }));

  };

  const style = {
    bgcolor: 'background.paper',
    border: '1.5px solid #3C6BA5',
    // boxShadow: 5,
    fontSize: "29px"
  }

  const rect = ["recruiterHead", "recruiterManager", "recruiterTeamLead", "recruiter"]

  const saless = ["salesHead", "salesManager", "salesTeamLead", "salesExecutive"]

  const ShowDataRec = tab === 'recruitment' && (
    Role === 'recruiterTrainee' ||
    Role === 'recruiterHead' ||
    Role === 'recruiterManager' ||
    Role === 'recruiterTeamLead' ||
    Role === 'recruiter' ||
    Role === 'admin'
  );

  const ShowDataSal = tab === 'sales' && (
    Role === 'salesTrainee' ||
    Role === 'salesHead' ||
    Role === 'salesManager' ||
    Role === 'salesTeamLead' ||
    Role === 'sales' ||
    Role === 'admin'
  );

  const ShowDataRec1 = (tab === 'recruitment' && (
    Role === 'recruiterTrainee' ||
    Role === 'recruiterHead' ||
    Role === 'recruiterManager' ||
    Role === 'recruiterTeamLead' ||
    Role === 'recruiter' ||
    Role === 'admin'
  )) || Role === 'admin';

  const ShowDataSal1 = (tab === 'sales' && (
    Role === 'salesTrainee' ||
    Role === 'salesHead' ||
    Role === 'salesManager' ||
    Role === 'salesTeamLead' ||
    Role === 'sales' ||
    Role === 'admin'
  )) || Role === 'admin';

  // console.log("rbar?.date", rbar.map((item) => item.date))
  // console.log("rbar?.date", rbar[0].date)

  return (
    <>
      <ToastContainer />
      <Helmet>
        <title> Dashboard | Foxearch </title>
      </Helmet>

      {/* <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, width: '89%', marginLeft:'17px', marginBottom:'20px'}}>
      <FormControl variant="outlined" size="small">
        <InputLabel id="dropdown-label">Dropdown</InputLabel>
        <Select
          labelId="dropdown-label"
          // value={dropdownValue}
          // onChange={handleDropdownChange}
          label="Dropdown"
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          <MenuItem value={10}>Ten</MenuItem>
          <MenuItem value={20}>Twenty</MenuItem>
          <MenuItem value={30}>Thirty</MenuItem>
        </Select>
      </FormControl>
    </Box> */} 


      {/* <ClickAwayListener> */}
      <div>
        <div style={{ display: 'flex', alignItems: 'center', position: 'absolute', top: 29, left: 115, right: 0 }}>
          <FormControl size="small" sx={{
            width: '25%',
            // borderRadius: '10px',
            border: "none",
            marginLeft: '20px',
            backgroundColor: "white",
            // border: '1px solid "#3C6BA5"', 
            // border: '1px solid #1E74B3',
            overflow: 'hidden',
          }}>
            <Select
              value={selectedDropdownValue}
              onChange={handleChangeDrop}
              size="small"
              className='dash_sr'
              sx={{
                borderRadius: '10px',
                border: '1px solid #1E74B3',
                '&:focus-visible': {
                  borderRadius: '10px',
                  border: '1px solid #1E74B3',
                },
              }}

              MenuProps={{
                disableScrollLock: true,
              }}
            >
              <MenuItem value='recruitment' disabled={!ShowDataRec1}>Recruitment</MenuItem>
              <MenuItem value='sales' disabled={!ShowDataSal1}>Sales</MenuItem>
              {/* <MenuItem value='item orders' disabled>Job Order</MenuItem> */}
              <MenuItem value='clients' disabled={clientAccess === "noaccess"}>Clients</MenuItem>
              <MenuItem value='Bug Reports' disabled>Placement</MenuItem>
            </Select>
          </FormControl>

          <Button color="custom" variant="contained" size="small" style={{ marginLeft: '12px' }} onClick={Funcc}>
            Search
          </Button>
        </div>
      </div>
      {/* </ClickAwayListener> */}
      <Container maxWidth="xl">
        <Typography variant="h5" sx={{ mb: 2, color: "#3C6BA5", mt: '-5px' }} >
          {/* Hi, Welcome back {localStorage.getItem('username')} */}
          Performance Pulse
        </Typography>

        <Grid container spacing={3}>
          {/* <Grid item xs={12} sm={6} md={3} 
          // onClick={() => handleTab('recruitment')}
          onClick={Role === ('recruiterHead' || "recruiterManager" || "recruiterTeamLead" || "recruiter") ? () => handleTab('recruitment') : null}
          >
            <AppWidgetSummary
              selected={tab === 'recruitment' && Role === ('recruiterHead' || "recruiterManager" || "recruiterTeamLead" || "recruiter")}
              // sx={style}
              sx={tab === 'recruitment' && Role === ('recruiterHead' || "recruiterManager" || "recruiterTeamLead" || "recruiter") ? { ...style, border: '3px solid #3C6BA5' } : style}
              title="Recruitment" total={per.totalPlacementsCounts} percentage={per.RecruitementPercenatge} perSign={per.RecruitementPercenatgeSign}
            />
          </Grid> */}

          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            onClick={(Role === 'recruiterHead' || Role === 'recruiterTrainee' || Role === 'recruiterManager' || Role === 'recruiterTeamLead' || Role === 'recruiter' || Role === 'admin') ? () => handleTab('recruitment') : null}
          >
            <AppWidgetSummary
              selected={tab === 'recruitment' && (Role === 'recruiterTrainee' || Role === 'recruiterHead' || Role === 'recruiterManager' || Role === 'recruiterTeamLead' || Role === 'recruiter' || Role === 'admin')}
              sx={tab === 'recruitment' && (Role === 'recruiterTrainee' || Role === 'recruiterHead' || Role === 'recruiterManager' || Role === 'recruiterTeamLead' || Role === 'recruiter' || Role === 'admin') ? { ...style, border: '3px solid #3C6BA5' } : style}
              title="Recruitment"
              total={per.totalPlacementsCounts}
              percentage={per.RecruitementPercenatge}
              perSign={per.RecruitementPercenatgeSign}
            />
            {/* <AppWidgetSummary
              selected={ShowDataRec}
              sx={ShowDataRec ? { ...style, border: '3px solid #3C6BA5' } : style}
              title="Recruitment"
              total={ShowDataRec ? per.totalPlacementsCounts : 0}
              percentage={ShowDataRec ? per.RecruitementPercenatge : 0}
              perSign={per.RecruitementPercenatgeSign}
            />  */}
          </Grid>

          {/* <Grid item xs={12} sm={6} md={3} onClick={() => handleTab('sales')}>
            <AppWidgetSummary
              selected={tab === 'sales'}
              // sx={style}
              sx={tab === 'sales' ? { ...style, border: '3px solid #3C6BA5' } : style}
              title="Sales" total={per.totalClientsCounts} 
              percentage={per.SalesPercenatge} perSign={per.SalesPercenatgeSign} 
              color="info" icon={'ant-design:apple-filled'} />
          </Grid> */}

          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            onClick={(Role === 'salesHead' || Role === 'salesTrainee' || Role === 'salesManager' || Role === 'salesTeamLead' || Role === 'salesExecutive' || Role === 'admin') ? () => handleTab('sales') : null}
          >
            {/* <AppWidgetSummary
              selected={ShowDataSal}
              sx={ShowDataSal ? { ...style, border: '3px solid #3C6BA5' } : style}
              title="Sales"
              total={ShowDataSal ? per.totalClientsCounts : 0}
              percentage={ShowDataSal ? per.SalesPercenatge : 0}
              perSign={per.SalesPercenatgeSign}
              color="info"
              icon={'ant-design:apple-filled'}
            /> */}
            <AppWidgetSummary
              selected={tab === 'sales'}
              // sx={style}
              sx={tab === 'sales' ? { ...style, border: '3px solid #3C6BA5' } : style}
              title="Sales" total={per.totalClientsCounts}
              percentage={per.SalesPercenatge} perSign={per.SalesPercenatgeSign}
              color="info" icon={'ant-design:apple-filled'} />
          </Grid>

          <Grid item xs={12} sm={6} md={3} onClick={clientAccess === "noaccess"? null:() => handleTab('re')}>
          {/* <Grid item xs={12} sm={6} md={3}> */}
            <AppWidgetSummary
              selected={tab === 're'}
              disabled={clientAccess === "noaccess"}
              // sx={style}
              sx={tab === 're' ? { ...style, border: '3px solid #3C6BA5' } : style}
              // title="Job Order" total={0} percentage={0} color="warning" icon={'ant-design:windows-filled'} />
              title="Clients" total={per.totalActiveCount} percentage={per.clientPercentage} 
              perSign={per.ClientPercentageSign}
              color="warning" icon={'ant-design:windows-filled'} />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              sx={style}
              title="Placement" total={0} percentage={0} color="error" icon={'ant-design:bug-filled'} />
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <Stack sx={{ bgcolor: 'background.paper', p: 2, borderRadius: 2, boxShadow: 2, gap: 1, flexDirection: 'row', border: '1px solid #3C6BA5' }}>
              <FormControl fullWidth size='small' >
                <InputLabel id="demo-simple-select-label">Duration</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={rec.timePeriod}
                  label="Duration"
                  onChange={(e) => { setRec({ ...rec, timePeriod: e.target.value }); getAll("", "", e.target.value) }}
                >
                  <MenuItem value={'day'}>Day</MenuItem>
                  <MenuItem value={'week'}>Week</MenuItem>
                  <MenuItem value={'month'}>Month</MenuItem>
                  <MenuItem value={'year'}>Year</MenuItem>

                </Select>
              </FormControl>
              {/* <FormControl fullWidth size='small' >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="From"
                    value={rec.from}
                    slotProps={{ textField: { size: 'small' } }}
                    onChange={(e) => setRec({ ...rec, from: e })}

                  />
                </LocalizationProvider>
              </FormControl>
              <FormControl fullWidth size='small' >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Till"
                    value={rec.till}
                    slotProps={{ textField: { size: 'small' } }}
                    onChange={(e) => setRec({ ...rec, till: e })}

                  />
                </LocalizationProvider>
              </FormControl> */}
              <FormControl fullWidth size='small' >
                <InputLabel>
                {tab === "re" ? "Individual" : "Individual / Team"}</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  // id="demo-simple-select"
                  value={rec.isTeam}
                  label={tab === "re" ? "Individual" :"Individual / Team"}
                  onChange={(e) => {
                    setRec({ ...rec, isTeam: e.target.value });
                    if (e.target.value === 'all') {
                      recTableByDate()
                      // salTableByDate()
                      allTableByDate()
                      getAll(e.target.value)
                    } else if (e.target.value === 'individual') {
                      handleUser()
                      // recTableByDate()
                      // salTableByDate()
                    }
                  }}
                >
                  <MenuItem value={'all'}>All</MenuItem>
                  <MenuItem value={'individual'}>Individual</MenuItem>
                  {tab !== "re" && (
                  <MenuItem value={'team'}>Team</MenuItem>
                  )}
                </Select>
              </FormControl>
              {rec.isTeam === 'team' && (
                tab === 'recruitment' ? (
                  <FormControl fullWidth size='small' >
                    <InputLabel id="demo-simple-select-label">Team</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={rec.tempRole}
                      label="Team"
                      onChange={(e) => { setRec({ ...rec, tempRole: e.target.value }); handleRole(e.target.value); recTableByDate(); salTableByDate() }}
                    >

                      <MenuItem value={'recruiterHead'}>RH</MenuItem>
                      <MenuItem value={'recruiterManager'}>RM</MenuItem>
                      <MenuItem value={'recruiterTeamLead'}>RL</MenuItem>

                    </Select>
                  </FormControl>
                ) : (
                  <FormControl fullWidth size='small' >
                    <InputLabel id="demo-simple-select-label">Team</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={rec.tempRole}
                      label="Team"
                      onChange={(e) => { setRec({ ...rec, tempRole: e.target.value }); handleRole(e.target.value) }}
                    >
                      <MenuItem value={'salesHead'}>SH</MenuItem>
                      <MenuItem value={'salesManager'}>SM</MenuItem>
                      <MenuItem value={'salesTeamLead'}>SL</MenuItem>

                    </Select>
                  </FormControl>
                )

              )}
              {rec.isTeam !== 'all' && (
                <FormControl fullWidth size='small' >
                  <InputLabel id="demo-simple-select-label">Users</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    // value={rec.userId}
                    value={tab !== "re" ? rec.userId : rec.cid}
                   
                    label="Users"
                    // onChange={(e) => {
                    //   setRec({ ...rec, userId: e.target.value });
                    //   getAll(rec.isTeam, e.target.value)
                    //   recTableByDate()
                    //   salTableByDate()
                    // }}
                    onChange={handleChange}
                  >
                    {tab !== "re" ? (
                      Users.map((item) => (<MenuItem value={item._id}>{item.contactPersonName} : {item.USERID}</MenuItem>))
                    ):(
                      clientList.map((item) => (<MenuItem value={item.cid}
                        style={{ color: item.status !== "Active" ? "red" : "" }}
                      >
                        {item.name}: {item.clientCompanyId}</MenuItem>))
                    )
                    }
                  </Select>
                </FormControl> 
              )}
            </Stack>


          </Grid>
          {tab === 'recruitment' && (
            <>
              <Grid item xs={12} md={6} lg={8}>
                <AppWebsiteVisits sx={{ border: '3px solid #3C6BA5', textAlign: 'center', color: "#3C6BA5" }}
                  // title="Ration of Submissions, Interviews & Placements"
                  title="Submissions | Interviews | Placements"
                  // subheader="(+43%) than last year"
                  chartLabels={[...rbar?.map((item) => item.date.replace(/-/g, "/"))]}
                  chartData={[
                    {
                      name: 'Submissions',
                      type: 'column',
                      fill: 'solid',
                      color: '#B7950B',
                      data: [...rbar?.map((item) => item.submission)],
                    },
                    {
                      name: 'Interviews',
                      type: 'column',
                      // type: 'area',
                      color: '#F4D03F',
                      fill: 'solid',
                      data: [...rbar?.map((item) => item.interview)],
                    },
                    {
                      name: 'Placements',
                      type: 'column',
                      // type: 'line',
                      color: '#2ECC71',
                      fill: 'solid',
                      data: [...rbar?.map((item) => item.placements)],
                    },
                  ]}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4} >
                <AppCurrentVisits sx={{
                  border: '3px solid #3C6BA5', textAlign: 'center',
                  color: "#3C6BA5",
                  height: recRaddial?.dateObject ? "100%" : "100%",

                }}
                  title="Recruitment Status"

                  date={
                    recRaddial?.dateObject
                      ? `${dayjs(recRaddial.dateObject.fromDate).format('MM/DD/YYYY')} 
                        - ${dayjs(recRaddial.dateObject.tillDate).format('MM/DD/YYYY')}`
                      : ''
                  }
                  chartData={[
                    { label: 'Prospects', value: recRaddial?.totalprospectsCounnts || 0 },
                    { label: 'Submissions', value: recRaddial?.totalCandidateSubmission || 0 },
                    { label: 'Interviews', value: recRaddial?.totalCandidateInterview || 0 },
                    { label: 'Placements', value: recRaddial?.totalCandidatePlacements || 0 },
                    { label: 'Backouts', value: recRaddial?.totalCandidateBackout || 0 },
                  ]}
                  chartColors={[
                    theme.palette.secondary.main,
                    '#B7950B',
                    '#F4D03F',
                    '#2ECC71',
                    '#CB4335',

                  ]}
                  val={per.RecruitementPercenatge}
                  centerLabel="Placements"
                  centerValue={recRaddial?.totalCandidatePlacements}
                // centerValue={tab === 'recruitment' ? (recRaddial?.totalCandidatePlacements || 0) : 0}
                // centerValue= {per.totalPlacementsCounts || 0}
                //  centerValue={tab === 'recruitment' ? (per.totalPlacementsCounts || 0) : 0}

                />
              </Grid>
              {/* <Grid item xs={12} md={12} lg={12}>
                <Stack sx={{ bgcolor: 'background.paper', p: 2, borderRadius: 2, boxShadow: 2, gap: 1, flexDirection: 'row', border: '1px solid #3C6BA5' }}>
                  <FormControl fullWidth size='small'>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label="From"
                        slotProps={{ textField: { size: 'small' } }}
                        value={valuee.fromDate ? dayjs(valuee.fromDate) : null}
                        // value={rec.fromDate ? dayjs(rec.fromDate) : (valuee.fromDate ? dayjs(valuee.fromDate) : null)}

                        onChange={(date) => handleDateChange(date, 'fromDate')}
                      />
                    </LocalizationProvider>
                  </FormControl>
                  <FormControl fullWidth size='small'>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label="Till"
                        slotProps={{ textField: { size: 'small' } }}
                        value={valuee.tillDate ? dayjs(valuee.tillDate) : null}
                        // value={rec.tillDate ? dayjs(rec.tillDate) : (valuee.tillDate ? dayjs(valuee.tillDate) : null)}

                        onChange={(date) => handleDateChange(date, 'tillDate')}
                      />
                    </LocalizationProvider>
                  </FormControl>
                </Stack>
              </Grid> */}
              <Grid container justifyContent="center" sx={{ marginTop: "11px", marginBottom: "-11px" }}>
                <Grid item sx={{ width: '13%', mx: '0.5%', backgroundColor: '#3C6BA5', borderRadius: "7px" }}>
                  {/* <FormControl fullWidth size='small'>
  <LocalizationProvider dateAdapter={AdapterDayjs}>
    <DatePicker
      slotProps={{ textField: { size: 'small' } }}
      value={valuee.fromDate ? dayjs(valuee.fromDate) : null}
      onChange={(date) => handleDateChange(date, 'fromDate')}
      sx={{
        '& .css-1ql7g3c-MuiButtonBase-root-MuiIconButton-root': {
          color: '#fff !important',
        },
        '& .css-d58xje-MuiInputBase-input-MuiOutlinedInput-input': {
          color: '#fff !important',
        },
      }}
    />
  </LocalizationProvider>
</FormControl> */}

                  <FormControl fullWidth size='small'>
                    <LocalizationProvider dateAdapter={AdapterDayjs} >
                      <DatePicker
                        slotProps={{ openPickerButton: { sx: { color: '#fff' } }, textField: { size: 'small' } }}
                        value={valuee.fromDate ? dayjs(valuee.fromDate) : null}
                        onChange={(date) => handleDateChange(date, 'fromDate')}
                        className="dash_dt"
                      //  renderInput={(props) => (
                      //   <TextField {...props} InputProps={{ className: 'bold-date' }} />
                      // )}
                      />

                    </LocalizationProvider>
                  </FormControl>
                </Grid>
                <Typography sx={{ marginTop: "6px", fontSize: "16px" }}>To</Typography>
                <Grid item sx={{ width: '13%', mx: '0.5%', backgroundColor: '#3C6BA5', borderRadius: "7px" }} >
                  <FormControl fullWidth size='small'>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        // label="Till"
                        slotProps={{ openPickerButton: { sx: { color: '#fff' } }, textField: { size: 'small' } }}
                        value={valuee.tillDate ? dayjs(valuee.tillDate) : null}
                        onChange={(date) => handleDateChange(date, 'tillDate')}
                        className="dash_dt"
                      // renderInput={(props) => (
                      //   <TextField {...props} InputProps={{ className: 'bold-date' }} />
                      // )}
                      />
                    </LocalizationProvider>
                  </FormControl>
                </Grid>
              </Grid>

              <Grid item xs={24} md={12} lg={12}>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650, border: '3px solid #3C6BA5', borderRadius: "10px" }} aria-label="simple table">
                    <TableHead>
                      <TableRow sx={{ justifyContent: 'flex-start', backgroundColor: 'text.secondary' }}>
                        <TableCell sx={{ p: 1, py: 0.5, color: 'white' }}>Name</TableCell>
                        <TableCell sx={{ p: 1, py: 0.5, color: 'white' }}>Prospects</TableCell>
                        <TableCell sx={{ p: 1, py: 0.5, color: 'white' }}>Submissions</TableCell>
                        <TableCell sx={{ p: 1, py: 0.5, color: 'white' }}>Unqualified</TableCell>
                        <TableCell sx={{ p: 1, py: 0.5, color: 'white' }}>Interviews</TableCell>
                        <TableCell sx={{ p: 1, py: 0.5, color: 'white' }}>Placements</TableCell>
                        <TableCell sx={{ p: 1, py: 0.5, color: 'white' }}>Backouts</TableCell>
                      </TableRow>
                    </TableHead>
                    {/* <TableBody>
                     
                        {tableByDate?.map((item, index) => (
                        <TableRow key={index} sx={[
                          // { background: 'text.background' },
                          { background: index % 2 === 0 ? 'text.background' : tablebg },
                          { '&:last-child td, &:last-child th': { border: 0 } },
                          { '&:hover': { boxShadow: 5 } },
                        ]}>
                          <TableCell sx={{ p: 1, py: 0.5 }}>{item.name}</TableCell>
                          <TableCell sx={{ p: 1, py: 0.5 }}>{item.prospects}</TableCell>
                          <TableCell sx={{ p: 1, py: 0.5 }}>{item.submission}</TableCell>
                          <TableCell sx={{ p: 1, py: 0.5 }}>{item.unqualified} ({((item.unqualified / item.submission) * 100).toFixed(2)}%)</TableCell>
                          <TableCell sx={{ p: 1, py: 0.5 }}>{item.interview} ({((item.interview / item.submission) * 100).toFixed(2)}%)</TableCell>
                          <TableCell sx={{ p: 1, py: 0.5 }}>{item.placements} ({((item.placements / item.submission) * 100).toFixed(2)}%)</TableCell>
                          <TableCell sx={{ p: 1, py: 0.5 }}>{item.backout} ({((item.backout / item.placements) * 100).toFixed(2)}%)</TableCell>
                        </TableRow>
                      ))}
                      <TableRow sx={{ backgroundColor: 'text.secondary' }}>
                        <TableCell sx={{ p: 1, py: 0.5, color: 'white', border: '1px solid #3C6BA5' }}>Total</TableCell>
                        <TableCell sx={{ p: 1, py: 0.5, color: 'white', border: '1px solid #3C6BA5' }}>{totall.totalprospectsCounnts}</TableCell>
                        <TableCell sx={{ p: 1, py: 0.5, color: 'white', border: '1px solid #3C6BA5' }}>{totall.totalCandidateSubmission}</TableCell>
                        <TableCell sx={{ p: 1, py: 0.5, color: 'white', border: '1px solid #3C6BA5' }}>{totall.totalCandidateUnqualified} ({((totall.totalCandidateUnqualified / totall.totalCandidateSubmission) * 100).toFixed(2)}%)</TableCell>
                        <TableCell sx={{ p: 1, py: 0.5, color: 'white', border: '1px solid #3C6BA5' }}>{totall.totalCandidateInterview} ({((totall.totalCandidateInterview / totall.totalCandidateSubmission) * 100).toFixed(2)}%)</TableCell>
                        <TableCell sx={{ p: 1, py: 0.5, color: 'white', border: '1px solid #3C6BA5' }}>{totall.totalCandidatePlacements} ({((totall.totalCandidatePlacements / totall.totalCandidateSubmission) * 100).toFixed(2)}%)</TableCell>
                        <TableCell sx={{ p: 1, py: 0.5, color: 'white', border: '1px solid #3C6BA5' }}>{totall.totalCandidateBackout} ({((totall.totalCandidateBackout / totall.totalCandidatePlacements) * 100).toFixed(2)}%)</TableCell>

                      </TableRow>

                    </TableBody> */}

                    <TableBody>
                      {tableByDate?.map((item, index) => (
                        <TableRow
                          key={index}
                          sx={[
                            { background: index % 2 === 0 ? 'text.background' : tablebg },
                            { '&:last-child td, &:last-child th': { border: 0 } },
                            { '&:hover': { boxShadow: 5 } },
                          ]}
                        >
                          <TableCell sx={{ p: 1, py: 0.5 }}>{item.name}</TableCell>
                          <TableCell sx={{ p: 1, py: 0.5 }}>{item.prospects}</TableCell>
                          <TableCell sx={{ p: 1, py: 0.5 }}>{item.submission}</TableCell>
                          <TableCell sx={{ p: 1, py: 0.5 }}>
                            {item.unqualified} (
                            {Number.isFinite((item.unqualified / item.submission) * 100)
                              ? ((item.unqualified / item.submission) * 100).toFixed(2)
                              : 0}
                            %)
                          </TableCell>
                          <TableCell sx={{ p: 1, py: 0.5 }}>
                            {item.interview} (
                            {Number.isFinite((item.interview / item.submission) * 100)
                              ? ((item.interview / item.submission) * 100).toFixed(2)
                              : 0}
                            %)
                          </TableCell>
                          <TableCell sx={{ p: 1, py: 0.5 }}>
                            {item.placements} (
                            {Number.isFinite((item.placements / item.submission) * 100)
                              ? ((item.placements / item.submission) * 100).toFixed(2)
                              : 0}
                            %)
                          </TableCell>
                          <TableCell sx={{ p: 1, py: 0.5 }}>
                            {item.backout} (
                            {Number.isFinite((item.backout / item.placements) * 100)
                              ? ((item.backout / item.placements) * 100).toFixed(2)
                              : 0}
                            %)
                          </TableCell>
                        </TableRow>
                      ))}
                      <TableRow sx={{ backgroundColor: 'text.secondary' }}>
                        <TableCell sx={{ p: 1, py: 0.5, color: 'white', border: '1px solid #3C6BA5', fontWeight: "bold" }}>Total</TableCell>
                        <TableCell sx={{ p: 1, py: 0.5, color: 'white', border: '1px solid #3C6BA5', fontWeight: "bold" }}>{totall.totalprospectsCounnts}</TableCell>
                        <TableCell sx={{ p: 1, py: 0.5, color: 'white', border: '1px solid #3C6BA5', fontWeight: "bold" }}>{totall.totalCandidateSubmission}</TableCell>
                        <TableCell sx={{ p: 1, py: 0.5, color: 'white', border: '1px solid #3C6BA5', fontWeight: "bold" }}>
                          {totall.totalCandidateUnqualified} (
                          {Number.isFinite((totall.totalCandidateUnqualified / totall.totalCandidateSubmission) * 100)
                            ? ((totall.totalCandidateUnqualified / totall.totalCandidateSubmission) * 100).toFixed(2)
                            : 0}
                          %)
                        </TableCell>
                        <TableCell sx={{ p: 1, py: 0.5, color: 'white', border: '1px solid #3C6BA5', fontWeight: "bold" }}>
                          {totall.totalCandidateInterview} (
                          {Number.isFinite((totall.totalCandidateInterview / totall.totalCandidateSubmission) * 100)
                            ? ((totall.totalCandidateInterview / totall.totalCandidateSubmission) * 100).toFixed(2)
                            : 0}
                          %)
                        </TableCell>
                        <TableCell sx={{ p: 1, py: 0.5, color: 'white', border: '1px solid #3C6BA5', fontWeight: "bold" }}>
                          {totall.totalCandidatePlacements} (
                          {Number.isFinite((totall.totalCandidatePlacements / totall.totalCandidateSubmission) * 100)
                            ? ((totall.totalCandidatePlacements / totall.totalCandidateSubmission) * 100).toFixed(2)
                            : 0}
                          %)
                        </TableCell>
                        <TableCell sx={{ p: 1, py: 0.5, color: 'white', border: '1px solid #3C6BA5', fontWeight: "bold" }}>
                          {totall.totalCandidateBackout} (
                          {Number.isFinite((totall.totalCandidateBackout / totall.totalCandidatePlacements) * 100)
                            ? ((totall.totalCandidateBackout / totall.totalCandidatePlacements) * 100).toFixed(2)
                            : 0}
                          %)
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </>
          )}
          {(tab === 'sales' && rec.isTeam === 'all') ? (
            <>
              <Grid item xs={12} md={6} lg={8}>
                <AppWebsiteVisits
                  sx={{ border: '3px solid #3C6BA5', textAlign: 'center', color: "#3C6BA5" }}
                  title="Pipeline | Meetings | Converted"
                  chartLabels={sbar?.map(item => item.date.replace(/-/g, "/"))}
                  chartData={[
                    {
                      name: 'Pipeline',
                      type: 'column',
                      fill: 'solid',
                      color: '#B7950B',
                      data: sbar?.map(item => item.pipeline),
                    },
                    {
                      name: 'Meetings',
                      type: 'column',
                      color: '#F4D03F',
                      fill: 'solid',
                      data: sbar?.map(item => item.meeting),
                    },
                    {
                      name: 'Converted',
                      type: 'column',
                      color: '#2ECC71',
                      fill: 'solid',
                      data: sbar?.map(item => item.converted),
                    },
                  ]}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <AppCurrentVisits
                  sx={{
                    border: '3px solid #3C6BA5', textAlign: 'center', color: "#3C6BA5",
                    height: saleRaddial1?.dateObject ? "100%" : "100%",

                  }}
                  title="Sales Status"
                  date={
                    saleRaddial1?.dateObject
                      ? `${dayjs(saleRaddial1.dateObject.fromDate).format('MM/DD/YYYY')} - ${dayjs(saleRaddial1.dateObject.tillDate).format('MM/DD/YYYY')}`
                      : ''
                  }
                  chartData={[
                    { label: 'Prospects', value: saleRaddial?.new || 0 },
                    { label: 'Pipeline', value: saleRaddial?.pipeline || 0 },
                    { label: 'Meetings', value: saleRaddial?.meeting || 0 },
                    // { label: 'Passiveclient', value: saleRaddial?.passive || 0 },
                    // { label: 'Converted', value: saleRaddial?.converted || 0 },
                    { label: 'Converted', value: saleRaddial?.converted || 0 },
                    { label: 'Passiveclient', value: saleRaddial?.passive || 0 },

                  ]}
                  chartColors={[
                    theme.palette.secondary.main,
                    '#B7950B',
                    '#F4D03F',
                    '#2ECC71',
                    '#CB4335',
                  ]}
                  centerLabel="Converted"
                  centerValue={saleRaddial?.converted || 0}
                />
              </Grid>

              {/* Date Picker */}
              <Grid container justifyContent="center" sx={{ marginTop: "11px", marginBottom: "-11px" }}>
                <Grid item sx={{ width: '13%', mx: '0.5%', backgroundColor: '#3C6BA5', borderRadius: "7px" }}>
                  <FormControl fullWidth size='small'>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        slotProps={{ openPickerButton: { sx: { color: '#fff' } }, textField: { size: 'small' } }}
                        value={valueee.fromDate ? dayjs(valueee.fromDate) : null}
                        onChange={(date) => handleDateChange1(date, 'fromDate')}
                        className="dash_dt"
                      />
                    </LocalizationProvider>
                  </FormControl>
                </Grid>
                <Typography sx={{ marginTop: "6px", fontSize: "16px" }}>To</Typography>
                <Grid item sx={{ width: '13%', mx: '0.5%', backgroundColor: '#3C6BA5', borderRadius: "7px" }}>
                  <FormControl fullWidth size='small'>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        slotProps={{ openPickerButton: { sx: { color: '#fff' } }, textField: { size: 'small' } }}
                        value={valueee.tillDate ? dayjs(valueee.tillDate) : null}
                        onChange={(date) => handleDateChange1(date, 'tillDate')}
                        className="dash_dt"
                      />
                    </LocalizationProvider>
                  </FormControl>
                </Grid>
              </Grid>

              {/* Data Table */}
              <Grid item xs={24} md={12} lg={12}>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650, border: '3px solid #3C6BA5', borderRadius: "10px" }} aria-label="simple table">
                    <TableHead>
                      <TableRow sx={{ justifyContent: 'flex-start', backgroundColor: 'text.secondary' }}>
                        <TableCell sx={{ p: 1, py: 0.5, color: 'white' }}>Name</TableCell>
                        <TableCell sx={{ p: 1, py: 0.5, color: 'white' }}>Prospects</TableCell>
                        <TableCell sx={{ p: 1, py: 0.5, color: 'white' }}>Pipeline</TableCell>
                        <TableCell sx={{ p: 1, py: 0.5, color: 'white' }}>Meetings</TableCell>
                        <TableCell sx={{ p: 1, py: 0.5, color: 'white' }}>Converted</TableCell>
                        <TableCell sx={{ p: 1, py: 0.5, color: 'white' }}>Passive</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {allSLData?.map((item, index) => (
                        <TableRow
                          key={index}
                          sx={[
                            { background: index % 2 === 0 ? 'text.background' : tablebg },
                            { '&:last-child td, &:last-child th': { border: 0 } },
                            { '&:hover': { boxShadow: 5 } },
                          ]}
                        >
                          <TableCell sx={{ p: 1, py: 0.5 }}>{item.addedBy}</TableCell>
                          <TableCell sx={{ p: 1, py: 0.5 }}>{item.statuses.new}</TableCell>
                          <TableCell sx={{ p: 1, py: 0.5 }}>
                            {item.statuses.pipeline}
                            {/* (
                            {Number.isFinite((item.statuses.pipeline / item.statuses.new) * 100)
                              ? ((item.statuses.pipeline / item.statuses.new) * 100).toFixed(2)
                              : 0}
                            %) */}
                          </TableCell>
                          <TableCell sx={{ p: 1, py: 0.5 }}>
                            {item.statuses.meetings}
                            {/* (
                            {Number.isFinite((item.statuses.meetings / item.statuses.pipeline) * 100)
                              ? ((item.statuses.meetings / item.statuses.pipeline) * 100).toFixed(2)
                              : 0}
                            %) */}
                          </TableCell>
                          <TableCell sx={{ p: 1, py: 0.5 }}>
                            {item.statuses.converted}
                            (
                            {Number.isFinite((item.statuses.converted / item.statuses.meetings) * 100)
                              ? ((item.statuses.converted / item.statuses.meetings) * 100).toFixed(2)
                              : 0}
                            %)
                          </TableCell>

                          <TableCell sx={{ p: 1, py: 0.5 }}>{item.statuses.passiveclient}</TableCell>
                        </TableRow>
                      ))}

                      <TableRow sx={{ backgroundColor: 'text.secondary' }}>
                        <TableCell sx={{ p: 1, py: 0.5, color: 'white', border: '1px solid #3C6BA5', fontWeight: "bold" }}>Total</TableCell>
                        <TableCell sx={{ p: 1, py: 0.5, color: 'white', border: '1px solid #3C6BA5', fontWeight: "bold" }}>{totals?.totalNew}</TableCell>
                        <TableCell sx={{ p: 1, py: 0.5, color: 'white', border: '1px solid #3C6BA5', fontWeight: "bold" }}>
                          {totals.totalPipeline}
                          {/* (
                          {Number.isFinite((totals.totalPipeline / totals.totalNew) * 100)
                            ? ((totals.totalPipeline / totals.totalNew) * 100).toFixed(2)
                            : 0}
                          %) */}
                        </TableCell>
                        <TableCell sx={{ p: 1, py: 0.5, color: 'white', border: '1px solid #3C6BA5', fontWeight: "bold" }}>
                          {totals.totalMeetings}
                          {/* (
                          {Number.isFinite((totals.totalMeetings / totals.totalPipeline) * 100)
                            ? ((totals.totalMeetings / totals.totalPipeline) * 100).toFixed(2)
                            : 0}
                          %) */}
                        </TableCell>
                        <TableCell sx={{ p: 1, py: 0.5, color: 'white', border: '1px solid #3C6BA5', fontWeight: "bold" }}>
                          {totals.totalConverted}
                          (
                          {Number.isFinite((totals.totalConverted / totals.totalMeetings) * 100)
                            ? ((totals.totalConverted / totals.totalMeetings) * 100).toFixed(2)
                            : 0}
                          %)
                        </TableCell>
                        <TableCell sx={{ p: 1, py: 0.5, color: 'white', border: '1px solid #3C6BA5', fontWeight: "bold" }}>{totals.totalPassiveclient}</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </>
          ) : (tab === 'sales' ? (
            <>
              <Grid item xs={12} md={6} lg={8}>
                <AppWebsiteVisits
                  sx={{ border: '3px solid #3C6BA5', textAlign: 'center', color: "#3C6BA5" }}
                  title="Pipeline | Meetings | Converted"
                  chartLabels={sbar?.map(item => item.date.replace(/-/g, "/"))}
                  chartData={[
                    {
                      name: 'Pipeline',
                      type: 'column',
                      fill: 'solid',
                      color: '#B7950B',
                      data: sbar?.map(item => item.pipeline),
                    },
                    {
                      name: 'Meetings',
                      type: 'column',
                      color: '#F4D03F',
                      fill: 'solid',
                      data: sbar?.map(item => item.meeting),
                    },
                    {
                      name: 'Converted',
                      type: 'column',
                      color: '#2ECC71',
                      fill: 'solid',
                      data: sbar?.map(item => item.converted),
                    },
                  ]}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <AppCurrentVisits
                  sx={{ border: '3px solid #3C6BA5', textAlign: 'center', color: "#3C6BA5" }}
                  title="Sales Status"
                  chartData={[
                    { label: 'Prospects', value: saleRaddial?.new || 0 },
                    { label: 'Pipeline', value: saleRaddial?.pipeline || 0 },
                    { label: 'Meetings', value: saleRaddial?.meeting || 0 },
                    // { label: 'Passiveclient', value: saleRaddial?.passive || 0 },
                    // { label: 'Converted', value: saleRaddial?.converted || 0 },
                    { label: 'Converted', value: saleRaddial?.converted || 0 },
                    { label: 'Passiveclient', value: saleRaddial?.passive || 0 },

                  ]}
                  chartColors={[
                    theme.palette.secondary.main,
                    '#B7950B',
                    '#F4D03F',
                    '#2ECC71',
                    '#CB4335',
                  ]}
                  centerLabel="Converted"
                  centerValue={saleRaddial?.converted || 0}
                />
              </Grid>

              {/* Date Picker */}
              <Grid container justifyContent="center" sx={{ marginTop: "11px", marginBottom: "-11px" }}>
                <Grid item sx={{ width: '13%', mx: '0.5%', backgroundColor: '#3C6BA5', borderRadius: "7px" }}>
                  <FormControl fullWidth size='small'>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        slotProps={{ openPickerButton: { sx: { color: '#fff' } }, textField: { size: 'small' } }}
                        value={valueee.fromDate ? dayjs(valueee.fromDate) : null}
                        onChange={(date) => handleDateChange1(date, 'fromDate')}
                        className="dash_dt"
                      />
                    </LocalizationProvider>
                  </FormControl>
                </Grid>
                <Typography sx={{ marginTop: "6px", fontSize: "16px" }}>To</Typography>
                <Grid item sx={{ width: '13%', mx: '0.5%', backgroundColor: '#3C6BA5', borderRadius: "7px" }}>
                  <FormControl fullWidth size='small'>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        slotProps={{ openPickerButton: { sx: { color: '#fff' } }, textField: { size: 'small' } }}
                        value={valueee.tillDate ? dayjs(valueee.tillDate) : null}
                        onChange={(date) => handleDateChange1(date, 'tillDate')}
                        className="dash_dt"
                      />
                    </LocalizationProvider>
                  </FormControl>
                </Grid>
              </Grid>

              {/* Data Table */}
              <Grid item xs={24} md={12} lg={12}>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650, border: '3px solid #3C6BA5', borderRadius: "10px" }} aria-label="simple table">
                    <TableHead>
                      <TableRow sx={{ justifyContent: 'flex-start', backgroundColor: 'text.secondary' }}>
                        <TableCell sx={{ p: 1, py: 0.5, color: 'white' }}>Name</TableCell>
                        <TableCell sx={{ p: 1, py: 0.5, color: 'white' }}>Prospects</TableCell>
                        <TableCell sx={{ p: 1, py: 0.5, color: 'white' }}>Pipeline</TableCell>
                        <TableCell sx={{ p: 1, py: 0.5, color: 'white' }}>Meetings</TableCell>
                        <TableCell sx={{ p: 1, py: 0.5, color: 'white' }}>Converted</TableCell>
                        <TableCell sx={{ p: 1, py: 0.5, color: 'white' }}>Passive</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {tableByDate1?.map((item, index) => (
                        <TableRow
                          key={index}
                          sx={[
                            { background: index % 2 === 0 ? 'text.background' : tablebg },
                            { '&:last-child td, &:last-child th': { border: 0 } },
                            { '&:hover': { boxShadow: 5 } },
                          ]}
                        >
                          <TableCell sx={{ p: 1, py: 0.5 }}>{item.name}</TableCell>
                          <TableCell sx={{ p: 1, py: 0.5 }}>{item.new}</TableCell>
                          <TableCell sx={{ p: 1, py: 0.5 }}>
                            {item.pipeline} (
                            {Number.isFinite((item.pipeline / item.new) * 100)
                              ? ((item.pipeline / item.new) * 100).toFixed(2)
                              : 0}
                            %)
                          </TableCell>
                          <TableCell sx={{ p: 1, py: 0.5 }}>
                            {item.meeting} (
                            {Number.isFinite((item.meeting / item.pipeline) * 100)
                              ? ((item.meeting / item.pipeline) * 100).toFixed(2)
                              : 0}
                            %)
                          </TableCell>
                          <TableCell sx={{ p: 1, py: 0.5 }}>
                            {item.converted} (
                            {Number.isFinite((item.converted / item.meeting) * 100)
                              ? ((item.converted / item.meeting) * 100).toFixed(2)
                              : 0}
                            %)
                          </TableCell>
                          <TableCell sx={{ p: 1, py: 0.5 }}>{item.passive}</TableCell>
                        </TableRow>
                      ))}

                      <TableRow sx={{ backgroundColor: 'text.secondary' }}>
                        <TableCell sx={{ p: 1, py: 0.5, color: 'white', border: '1px solid #3C6BA5', fontWeight: "bold" }}>Total</TableCell>
                        <TableCell sx={{ p: 1, py: 0.5, color: 'white', border: '1px solid #3C6BA5', fontWeight: "bold" }}>{totallSales?.new}</TableCell>
                        <TableCell sx={{ p: 1, py: 0.5, color: 'white', border: '1px solid #3C6BA5', fontWeight: "bold" }}>
                          {totallSales?.pipeline} (
                          {Number.isFinite((totallSales?.pipeline / totallSales?.new) * 100)
                            ? ((totallSales?.pipeline / totallSales?.new) * 100).toFixed(2)
                            : 0}
                          %)
                        </TableCell>
                        <TableCell sx={{ p: 1, py: 0.5, color: 'white', border: '1px solid #3C6BA5', fontWeight: "bold" }}>
                          {totallSales?.meeting} (
                          {Number.isFinite((totallSales?.meeting / totallSales?.pipeline) * 100)
                            ? ((totallSales?.meeting / totallSales?.pipeline) * 100).toFixed(2)
                            : 0}
                          %)
                        </TableCell>
                        <TableCell sx={{ p: 1, py: 0.5, color: 'white', border: '1px solid #3C6BA5', fontWeight: "bold" }}>
                          {totallSales?.converted} (
                          {Number.isFinite((totallSales?.converted / totallSales?.meeting) * 100)
                            ? ((totallSales?.converted / totallSales?.meeting) * 100).toFixed(2)
                            : 0}
                          %)
                        </TableCell>
                        <TableCell sx={{ p: 1, py: 0.5, color: 'white', border: '1px solid #3C6BA5', fontWeight: "bold" }}>{totallSales?.passive}</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </>
          ) : null)}

          {tab === 're' && (
            <>
              <Grid item xs={12} md={6} lg={8}>
                <AppWebsiteVisits sx={{ border: '3px solid #3C6BA5', textAlign: 'center', color: "#3C6BA5" }}
                  // title="Revenue" sx={{ border: '3px solid #3C6BA5', textAlign: 'center', color: "#3C6BA5" }}
                  // title="Client Status" sx={{ border: '3px solid #3C6BA5', textAlign: 'center', color: "#3C6BA5" }}
                  // subheader="(+43%) than last year"
                 
                 title="Submissions | Interviews | Placements"
                
                  // chartLabels={[
                  //   '01/01/2003',
                  //   '02/01/2003',
                  //   '03/01/2003',
                  //   '04/01/2003',
                  //   '05/01/2003',
                  //   '06/01/2003',
                  //   '07/01/2003',
                  //   '08/01/2003',
                  //   '09/01/2003',
                  //   '10/01/2003',
                  //   '11/01/2003',
                  // ]}
                  // chartData={[
                  //   {
                  //     // name: 'Last Month',
                  //     name: 'Submissions',
                  //     type: 'column',
                  //     fill: 'solid',
                  //     color: '#B7950B',
                  //     data: [23, 11, 22, 27, 13, 22, 37, 21, 44, 22, 30],
                  //   },
                  //   {
                  //     // name: 'Current Month',
                  //     name: 'Interviews',
                  //     type: 'column',
                  //     // type: 'area',
                  //     color: '#F4D03F',
                  //     fill: 'solid',
                  //     data: [44, 55, 41, 67, 22, 43, 21, 41, 56, 27, 43],
                  //   },
                  //   {
                  //     // name: 'Current Month',
                  //     name: 'Placements',
                  //     type: 'column',
                  //     // type: 'area',
                  //     color: '#F4D03F',
                  //     fill: 'solid',
                  //     data: [44, 55, 41, 67, 22, 43, 21, 41, 56, 27, 43],
                  //   }
                  // ]}

                  chartLabels={[...rbarclient?.map((item) => item.date.replace(/-/g, "/"))]}
                  chartData={[
                    {
                      name: 'Submissions',
                      type: 'column',
                      fill: 'solid',
                      color: '#B7950B',
                      data: [...rbarclient?.map((item) => item.submission)],
                    },
                    {
                      name: 'Interviews',
                      type: 'column',
                      // type: 'area',
                      color: '#F4D03F',
                      fill: 'solid',
                      data: [...rbarclient?.map((item) => item.interview)],
                    },
                    {
                      name: 'Placements',
                      type: 'column',
                      // type: 'line',
                      color: '#2ECC71',
                      fill: 'solid',
                      data: [...rbarclient?.map((item) => item.placements)],
                    },
                  ]}
                />
              </Grid>

              <Grid item xs={12} md={6} lg={4}>
                <AppCurrentVisits sx={{ border: '3px solid #3C6BA5', textAlign: 'center', color: "#3C6BA5",
                  height: clientRadDate?.dateObject ? "100%" : "100%",
                  
                }}
                title="Client Status"
               
                date={
                  clientRadDate?.dateObject
                    ? `${dayjs(clientRadDate.dateObject.fromDate).format('MM/DD/YYYY')} - ${dayjs(clientRadDate.dateObject.tillDate).format('MM/DD/YYYY')}`
                    : ''
                }

                chartData={[
                  // { label: 'Prospects', value: recRaddial?.totalprospectsCounnts || 0 },
                  { label: 'Submissions', value: clientRad?.submittedCount || 0 },
                  { label: 'Interviews', value: clientRad?.interviewCount || 0 },
                  { label: 'Placements', value: clientRad?.placementCount || 0 },
                  { label: 'Backouts', value: clientRad?.backoutCount || 0 },
                  // { label: 'Backouts', value: clientRad?.backoutCount || 0 },
                ]}
                chartColors={[
                  // theme.palette.secondary.main,
                  '#B7950B',
                  '#F4D03F',
                  '#2ECC71',
                  '#CB4335',

                ]}
                // val={per.RecruitementPercenatge}
                centerLabel="Placements"
                centerValue={clientRad?.placementCount}

                  // chartData={[
                  //   { label: 'Submissions', value: 10 },
                  //   { label: 'Interviews', value: 20 },
                  //   { label: 'Placments', value: 30 },
                  //   { label: 'Backouts', value: 30 }
                  // ]}
                  // chartColors={[
                  //   theme.palette.secondary.main,
                  //   '#B7950B',
                  //   '#F4D03F',
                  //   '#2ECC71',
                  //   '#CB4335',
                  // ]}
                  //  centerLabel="Placements"
                   
                />
              </Grid>

              <Grid container justifyContent="center" sx={{ marginTop: "11px", marginBottom: "-11px" }}>
                <Grid item sx={{ width: '13%', mx: '0.5%', backgroundColor: '#3C6BA5', borderRadius: "7px" }}>
                  <FormControl fullWidth size='small'>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        slotProps={{ openPickerButton: { sx: { color: '#fff' } }, textField: { size: 'small' } }}
                        value={valueeee.fromDate ? dayjs(valueeee.fromDate) : null}
                        onChange={(date) => handleDateChange2(date, 'fromDate')}
                        className="dash_dt"
                      />
                    </LocalizationProvider>
                  </FormControl>
                </Grid>
                <Typography sx={{ marginTop: "6px", fontSize: "16px" }}>To</Typography>
                <Grid item sx={{ width: '13%', mx: '0.5%', backgroundColor: '#3C6BA5', borderRadius: "7px" }}>
                  <FormControl fullWidth size='small'>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        slotProps={{ openPickerButton: { sx: { color: '#fff' } }, textField: { size: 'small' } }}
                        value={valueeee.tillDate ? dayjs(valueeee.tillDate) : null}
                        onChange={(date) => handleDateChange2(date, 'tillDate')}
                        className="dash_dt"
                      />
                    </LocalizationProvider>
                  </FormControl>
                </Grid>
              </Grid>

              <Grid item xs={24} md={12} lg={12} >
                <TableContainer component={Paper}>
                  {/* <Table sx={{ minWidth: 650 }} aria-label="simple table"> */}
                  <Table sx={{ minWidth: 650, border: '3px solid #3C6BA5', borderRadius: "10px" }} aria-label="simple table">
               
                    <TableHead>
                      <TableRow sx={{ justifyContent: 'flex-start', backgroundColor: 'text.secondary' }}>
                        <TableCell sx={{ p: 1, py: 0.5, color: 'white' }}>Name</TableCell>
                        <TableCell sx={{ p: 1, py: 0.5, color: 'white' }}>Submissions</TableCell>
                        <TableCell sx={{ p: 1, py: 0.5, color: 'white' }}>Unqualified</TableCell>
                        {/* <TableCell sx={{ p: 1, py: 0.5, color: 'white' }}>Finalised</TableCell> */}
                        <TableCell sx={{ p: 1, py: 0.5, color: 'white' }}>Interviews</TableCell>
                        <TableCell sx={{ p: 1, py: 0.5, color: 'white' }}>Placements</TableCell>
                        <TableCell sx={{ p: 1, py: 0.5, color: 'white' }}>Backouts</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {tableByDateClient?.map((item, index) => (
                        <TableRow  
                          key={index}
                          sx={[
                            { background: index % 2 === 0 ? 'text.background' : tablebg },
                            { '&:last-child td, &:last-child th': { border: 0 } },
                            { '&:hover': { boxShadow: 5 } },
                          ]}
                        >
                          <TableCell sx={{ p: 1, py: 0.5 }} style={{color:item.status !== "Active" ? "red": ""}}>{item.name}</TableCell>
                          {/* <TableCell sx={{ p: 1, py: 0.5 }}>{item.prospects}</TableCell> */}
                          <TableCell sx={{ p: 1, py: 0.5 }}>{item.submittedCount}</TableCell>
                          <TableCell sx={{ p: 1, py: 0.5 }}>
                            {item.Unqualified} (
                            {Number.isFinite((item.Unqualified / item.submittedCount) * 100)
                              ? ((item.Unqualified / item.submittedCount) * 100).toFixed(2)
                              : 0}
                            %)
                          </TableCell>
                          <TableCell sx={{ p: 1, py: 0.5 }}>
                            {item.interviewCount} (
                            {Number.isFinite((item.interviewCount / item.submittedCount) * 100)
                              ? ((item.interviewCount / item.submittedCount) * 100).toFixed(2)
                              : 0}
                            %)
                          </TableCell>
                          <TableCell sx={{ p: 1, py: 0.5 }}>
                            {item.placementCount} (
                            {Number.isFinite((item.placementCount / item.submittedCount) * 100)
                              ? ((item.placementCount  / item.submittedCount) * 100).toFixed(2)
                              : 0}
                            %)
                          </TableCell>
                          <TableCell sx={{ p: 1, py: 0.5 }}>
                            {item.backoutCount} (
                            {Number.isFinite((item.backoutCount / item.placementCount) * 100)
                              ? ((item.backoutCount / item.placementCount) * 100).toFixed(2)
                              : 0}
                            %)
                          </TableCell>
                        </TableRow>
                      ))}
                      <TableRow sx={{ backgroundColor: 'text.secondary' }}>
                        <TableCell sx={{ p: 1, py: 0.5, color: 'white', border: '1px solid #3C6BA5', fontWeight: "bold" }}>Total</TableCell>
                        {/* <TableCell sx={{ p: 1, py: 0.5, color: 'white', border: '1px solid #3C6BA5', fontWeight: "bold" }}>{totall.totalprospectsCounnts}</TableCell> */}
                        <TableCell sx={{ p: 1, py: 0.5, color: 'white', border: '1px solid #3C6BA5', fontWeight: "bold" }}>{tableByDateClientTotal.totalSubmittedCount}</TableCell>
                        <TableCell sx={{ p: 1, py: 0.5, color: 'white', border: '1px solid #3C6BA5', fontWeight: "bold" }}>
                          {tableByDateClientTotal.totalUnqualifiedCount} (
                          {Number.isFinite((tableByDateClientTotal.totalUnqualifiedCount / tableByDateClientTotal.totalSubmittedCount) * 100)
                            ? ((tableByDateClientTotal.totalUnqualifiedCount / tableByDateClientTotal.totalSubmittedCount) * 100).toFixed(2)
                            : 0}
                          %)
                        </TableCell>
                        <TableCell sx={{ p: 1, py: 0.5, color: 'white', border: '1px solid #3C6BA5', fontWeight: "bold" }}>
                          {tableByDateClientTotal.totalInterviewCount} (
                          {Number.isFinite((tableByDateClientTotal.totalInterviewCount / tableByDateClientTotal.totalSubmittedCount) * 100)
                            ? ((tableByDateClientTotal.totalInterviewCount / tableByDateClientTotal.totalSubmittedCount) * 100).toFixed(2)
                            : 0}
                          %)
                        </TableCell>
                        <TableCell sx={{ p: 1, py: 0.5, color: 'white', border: '1px solid #3C6BA5', fontWeight: "bold" }}>
                          {tableByDateClientTotal.totalPlacementCount} (
                          {Number.isFinite((tableByDateClientTotal.totalPlacementCount / tableByDateClientTotal.totalSubmittedCount) * 100)
                            ? ((tableByDateClientTotal.totalPlacementCount / tableByDateClientTotal.totalSubmittedCount) * 100).toFixed(2)
                            : 0}
                          %)
                        </TableCell>
                        <TableCell sx={{ p: 1, py: 0.5, color: 'white', border: '1px solid #3C6BA5', fontWeight: "bold" }}>
                          {tableByDateClientTotal.totalBackoutCount} (
                          {Number.isFinite((tableByDateClientTotal.totalBackoutCount / tableByDateClientTotal.totalSubmittedCount) * 100)
                            ? ((tableByDateClientTotal.totalBackoutCount / tableByDateClientTotal.totalSubmittedCount) * 100).toFixed(2)
                            : 0}
                          %)
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </>
          )}

          {/* <Grid item xs={12} md={6} lg={8}>
            <AppConversionRates
              title="Conversion Rates"
              subheader="(+43%) than last year"
              chartData={[
                { label: 'Italy', value: 400 },
                { label: 'Japan', value: 430 },
                { label: 'China', value: 448 },
                { label: 'Canada', value: 470 },
                { label: 'France', value: 540 },
                { label: 'Germany', value: 580 },
                { label: 'South Korea', value: 690 },
                { label: 'Netherlands', value: 1100 },
                { label: 'United States', value: 1200 },
                { label: 'United Kingdom', value: 1380 },
              ]}
            />
          </Grid> */}

          {/* <Grid item xs={12} md={6} lg={4}>
            <AppCurrentSubject
              title="Current Subject"
              chartLabels={['English', 'History', 'Physics', 'Geography', 'Chinese', 'Math']}
              chartData={[
                { name: 'Series 1', data: [80, 50, 30, 40, 100, 20] },
                { name: 'Series 2', data: [20, 30, 40, 80, 20, 80] },
                { name: 'Series 3', data: [44, 76, 78, 13, 43, 10] },
              ]}
              chartColors={[...Array(6)].map(() => theme.palette.text.secondary)}
            />
          </Grid> */}

          {/* <Grid item xs={12} md={6} lg={8}>
            <AppNewsUpdate
              title="News Update"
              list={[...Array(5)].map((_, index) => ({
                id: faker.datatype.uuid(),
                title: faker.name.jobTitle(),
                description: faker.name.jobTitle(),
                image: `/assets/images/covers/cover_${index + 1}.jpg`,
                postedAt: faker.date.recent(),
              }))}
            />
          </Grid> */}

          {/* <Grid item xs={12} md={6} lg={4}>
            <AppOrderTimeline
              title="Order Timeline"
              list={[...Array(5)].map((_, index) => ({
                id: faker.datatype.uuid(),
                title: [
                  '1983, orders, $4220',
                  '12 Invoices have been paid',
                  'Order #37745 from September',
                  'New order placed #XF-2356',
                  'New order placed #XF-2346',
                ][index],
                type: `order${index + 1}`,
                time: faker.date.past(),
              }))}
            />
          </Grid> */}

          {/* <Grid item xs={12} md={6} lg={4}>
            <AppTrafficBySite
              title="Traffic by Site"
              list={[
                {
                  name: 'FaceBook',
                  value: 323234,
                  icon: <Iconify icon={'eva:facebook-fill'} color="#1877F2" width={32} />,
                },
                {
                  name: 'Google',
                  value: 341212,
                  icon: <Iconify icon={'eva:google-fill'} color="#DF3E30" width={32} />,
                },
                {
                  name: 'Linkedin',
                  value: 411213,
                  icon: <Iconify icon={'eva:linkedin-fill'} color="#006097" width={32} />,
                },
                {
                  name: 'Twitter',
                  value: 443232,
                  icon: <Iconify icon={'eva:twitter-fill'} color="#1C9CEA" width={32} />,
                },
              ]}
            />
          </Grid> */}

          {/* <Grid item xs={12} md={6} lg={8}>
            <AppTasks
              title="Tasks"
              list={[
                { id: '1', label: 'Create FireStone Logo' },
                { id: '2', label: 'Add SCSS and JS files if required' },
                { id: '3', label: 'Stakeholder Meeting' },
                { id: '4', label: 'Scoping & Estimations' },
                { id: '5', label: 'Sprint Showcase' },
              ]}
            />
          </Grid> */}
        </Grid>
      </Container>
    </>
  );
}
