import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { styled, alpha } from '@mui/material/styles';
// import {  RouterLink } from 'react-router-dom';
import { Box, Link, Button, Drawer, Typography, Avatar, Stack } from '@mui/material';
// mock
import account from '../../../_mock/account';
// hooks
import useResponsive from '../../../hooks/useResponsive';
// components
import Logo from '../../../components/logo';
import Iconify from '../../../components/iconify';
import Scrollbar from '../../../components/scrollbar';
import NavSection from '../../../components/nav-section';
//
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

import navConfig from './config';
// import iig from '../../../../public/assets/logo_or.png'
// ----------------------------------------------------------------------

// const NAV_WIDTH = 100;

// const NAV_WIDTH = 280;

const MinAccount = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: alpha(theme.palette.grey[500], 0.12),
}));

const StyledAccount = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: alpha(theme.palette.grey[500], 0.12),
}));
// ----------------------------------------------------------------------

Nav.propTypes = {
  openNav: PropTypes.bool,
  onCloseNav: PropTypes.func,
};

export default function Nav({ openNav, onCloseNav }) {
  const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

  const role = useSelector((state) => state.userData.data.role);

  const rights = useSelector((state) => state.userData.data.accessRights);

  const [lRole, setLRole] = useState('admin');
  const { pathname } = useLocation();
  const [NAV_WIDTH, setNavwidth] = useState(100);
  const isDesktop = useResponsive('up', 'lg');
  const temp = [];
  const [navConfig1, setNavConfig1] = useState([
    {
      title: 'Setting',
      icon: icon('gear'),
    },

  ]);

  useEffect(() => {
    const trole = localStorage.getItem('role');
    setLRole(trole);
  }, []);
  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  useEffect(() => {
    console.log('=redux========', role);

    // const dashboard = {
    //   title: 'Dashboard',
    //   // path: '/companymanegement',
    //   icon: icon('dashboard'),
    // };
    const setting = {
      title: 'Setting',

      // path: '/companymanegement',

      icon: icon('gear'),
    };
    const superadmin = {
      title: 'User Management',
      path: '/addCompany',
      icon: icon('management'),
    };
    const admin = {
      title: 'User Management',
      path: '/management',
      icon: icon('management'),
    };
    const rworksheet = {
      title: 'Recruiter Worksheet',
      path: '/worksheet',
      icon: icon('worksheet'),
    };

    const recruiterManager = {
      title: 'User Management',
      path: '/recruiterTeamLead',
      icon: icon('management'),
    };
    const recruiterManagement = {
      title: 'User Management',
      path: '/recruiterManagement',
      icon: icon('management'),
    };
    const rManagement = {
      title: 'User Management',
      path: '/recuiterManager',
      icon: icon('management'),
    };
    const salesworksheet = {
      title: 'Sales Worksheet',
      path: '/salesworksheet',
      icon: icon('management'),
    };
    const sManagement = {
      title: 'User Management',
      path: '/salesManager',
      icon: icon('management'),
    };
    const salesManager = {
      title: 'Sales Management',
      path: '/salesTeamLead',
      icon: icon('management'),
    };
    const salesManagement = {
      title: 'User Management',
      path: '/salesManagement',
      icon: icon('management'),
    };
    const resumes = {
      title: 'Database',
      path: '/resumedatabase',
      icon: icon('database'),
    };

    const jobs = {
      title: 'Jobs',
      path: '/jobs',
      icon: icon('job'),
    };
    const clients = {
      title: 'Clients',
      path: '/clients',
      icon: icon('client'),
    };
    const leads = {
      title: 'Leads',
      path: '/clients',
      icon: icon('client'),
    };
    const placement = {
      title: 'Placement',
      path: '/clients',
      icon: icon('client'),
    };

    const masterData = {
      title: 'Master Data',
      path: '/masterData',
      icon: icon('client'),
    };

    const dashboard = {
      title: 'Dashboard',
      path: '/app',
      icon: icon('dashboard'),
    };

    if (role === 'superadmin') {
      setNavConfig1([superadmin, masterData, ...navConfig1]);
    } else if (role === 'admin') {
      setNavConfig1([admin, rworksheet, salesworksheet, resumes, jobs, clients, leads, placement, dashboard, ...navConfig1]);
    } else if (role === 'recruiterManager') {
      temp.push(recruiterManager, rworksheet);
      // setNavConfig1([recruiterManager]);
      if (rights?.worksheet !== 'noaccess' && rights?.worksheet !== '') {
        temp.push(salesworksheet);
      }
      if (rights?.clients !== 'noaccess' && rights?.clients !== '') {
        temp.push(clients);
      }
      if (rights?.jobs !== 'noaccess' && rights?.jobs !== '') {
        temp.push(jobs);
      }
      if (rights?.resumes !== 'noaccess' && rights?.resumes !== '') {
        temp.push(resumes);
      }
      if (rights?.leads !== 'noaccess' && rights?.leads !== '') {
        temp.push(leads);
      }
      if (rights?.placements !== 'noaccess' && rights?.placements !== '') {
        temp.push(placement);
      }

      if (rights?.dashboard !== 'noaccess' && rights?.dashboard !== '') {
        temp.push(dashboard);
      }

      temp.push(setting);
      console.log('temp arry', temp);
      setNavConfig1(temp);
    } else if (role === 'recruiterHead') {
      // setNavConfig1([rManagement]);
      temp.push(rManagement, rworksheet);
      if (rights?.worksheet !== 'noaccess' && rights?.worksheet !== '') {
        temp.push(salesworksheet);
      }
      if (rights?.clients !== 'noaccess' && rights?.clients !== '') {
        temp.push(clients);
      }
      if (rights?.jobs !== 'noaccess' && rights?.jobs !== '') {
        temp.push(jobs);
      }
      if (rights?.resumes !== 'noaccess' && rights?.resumes !== '') {
        temp.push(resumes);
      }
      if (rights?.leads !== 'noaccess' && rights?.leads !== '') {
        temp.push(leads);
      }
      if (rights?.placements !== 'noaccess' && rights?.placements !== '') {
        temp.push(placement);
      }
      if (rights?.dashboard !== 'noaccess' && rights?.dashboard !== '') {
        temp.push(dashboard);
      }
      temp.push(setting);
      console.log('temp arry', temp);
      setNavConfig1(temp);
    } else if (role === 'recruiter') {
      temp.push(rworksheet);
      if (rights?.worksheet !== 'noaccess' && rights?.worksheet !== '') {
        temp.push(salesworksheet);
      }
      if (rights?.clients !== 'noaccess' && rights?.clients !== '') {
        temp.push(clients);
      }
      if (rights?.jobs !== 'noaccess' && rights?.jobs !== '') {
        temp.push(jobs);
      }
      if (rights?.resumes !== 'noaccess' && rights?.resumes !== '') {
        temp.push(resumes);
      }
      if (rights?.leads !== 'noaccess' && rights?.leads !== '') {
        temp.push(leads);
      }
      if (rights?.placements !== 'noaccess' && rights?.placements !== '') {
        temp.push(placement);
      }

      if (rights?.dashboard !== 'noaccess' && rights?.dashboard !== '') {
        temp.push(dashboard);
      }

      temp.push(setting);
      console.log('temp arry', temp);
      setNavConfig1(temp);
    } else if (role === 'recruiterTrainee') {
      temp.push(rworksheet);
      if (rights?.worksheet !== 'noaccess' && rights?.worksheet !== '') {
        temp.push(salesworksheet);
      }
      if (rights?.clients !== 'noaccess' && rights?.clients !== '') {
        temp.push(clients);
      }
      if (rights?.jobs !== 'noaccess' && rights?.jobs !== '') {
        temp.push(jobs);
      }
      if (rights?.resumes !== 'noaccess' && rights?.resumes !== '') {
        temp.push(resumes);
      }
      if (rights?.leads !== 'noaccess' && rights?.leads !== '') {
        temp.push(leads);
      }
      if (rights?.placements !== 'noaccess' && rights?.placements !== '') {
        temp.push(placement);
      }
      if (rights?.dashboard !== 'noaccess' && rights?.dashboard !== '') {
        temp.push(dashboard);
      }
      temp.push(setting);
      console.log('temp arry', temp);
      setNavConfig1(temp);
    } else if (role === 'salesTrainee') {
      temp.push(salesworksheet);
      if (rights?.worksheet !== 'noaccess' && rights?.worksheet !== '') {
        temp.push(rworksheet);
      }
      if (rights?.clients !== 'noaccess' && rights?.clients !== '') {
        temp.push(clients);
      }
      if (rights?.jobs !== 'noaccess' && rights?.jobs !== '') {
        temp.push(jobs);
      }
      if (rights?.resumes !== 'noaccess' && rights?.resumes !== '') {
        temp.push(resumes);
      }
      if (rights?.leads !== 'noaccess' && rights?.leads !== '') {
        temp.push(leads);
      }
      if (rights?.placements !== 'noaccess' && rights?.placements !== '') {
        temp.push(placement);
      }
      if (rights?.dashboard !== 'noaccess' && rights?.dashboard !== '') {
        temp.push(dashboard);
      }
      temp.push(setting);
      console.log('temp arry', temp);
      setNavConfig1(temp);
    } else if (role === 'salesHead') {
      // setNavConfig1([sManagement]);
      temp.push(sManagement, salesworksheet);
      if (rights?.worksheet !== 'noaccess' && rights?.worksheet !== '') {
        temp.push(rworksheet);
      }
      if (rights?.clients !== 'noaccess' && rights?.clients !== '') {
        temp.push(clients);
      }
      if (rights?.jobs !== 'noaccess' && rights?.jobs !== '') {
        temp.push(jobs);
      }
      if (rights?.resumes !== 'noaccess' && rights?.resumes !== '') {
        temp.push(resumes);
      }
      if (rights?.leads !== 'noaccess' && rights?.leads !== '') {
        temp.push(leads);
      }
      if (rights?.placements !== 'noaccess' && rights?.placements !== '') {
        temp.push(placement);
      }

      if (rights?.dashboard !== 'noaccess' && rights?.dashboard !== '') {
        temp.push(dashboard);
      }
      temp.push(setting);
      console.log('temp arry', temp);
      setNavConfig1(temp);
    } else if (role === 'salesManager') {
      temp.push(salesManager, salesworksheet);
      if (rights?.worksheet !== 'noaccess' && rights?.worksheet !== '') {
        temp.push(rworksheet);
      }
      if (rights?.clients !== 'noaccess' && rights?.clients !== '') {
        temp.push(clients);
      }
      if (rights?.jobs !== 'noaccess' && rights?.jobs !== '') {
        temp.push(jobs);
      }
      if (rights?.resumes !== 'noaccess' && rights?.resumes !== '') {
        temp.push(resumes);
      }
      if (rights?.leads !== 'noaccess' && rights?.leads !== '') {
        temp.push(leads);
      }
      if (rights?.placements !== 'noaccess' && rights?.worksheet !== '') {
        temp.push(placement);
      }
      if (rights?.dashboard !== 'noaccess' && rights?.dashboard !== '') {
        temp.push(dashboard);
      }
      temp.push(setting);
      console.log('temp arry salesManager', temp);
      setNavConfig1(temp);
    } else if (role === 'salesExecutive') {
      temp.push(salesworksheet);
      // setNavConfig1([salesworksheet, ...navConfig1]);
      if (rights?.worksheet !== 'noaccess' && rights?.worksheet !== '') {
        temp.push(rworksheet);
      }
      if (rights?.clients !== 'noaccess' && rights?.clients !== '') {
        temp.push(clients);
      }
      if (rights?.jobs !== 'noaccess' && rights?.jobs !== '') {
        temp.push(jobs);
      }
      if (rights?.resumes !== 'noaccess' && rights?.resumes !== '') {
        temp.push(resumes);
      }
      if (rights?.leads !== 'noaccess' && rights?.leads !== '') {
        temp.push(leads);
      }
      if (rights?.placements !== 'noaccess' && rights?.placements !== '') {
        temp.push(placement);
      }

      if (rights?.dashboard !== 'noaccess' && rights?.dashboard !== '') {
        temp.push(dashboard);
      }
      temp.push(setting);
      console.log('temp arry', temp);
      setNavConfig1(temp);
    } else if (role === 'recruiterTeamLead') {
      temp.push(recruiterManagement, rworksheet);
      // setNavConfig1([recruiterManagement]);
      if (rights?.worksheet !== 'noaccess' && rights?.worksheet !== '') {
        temp.push(salesworksheet);
      }
      if (rights?.clients !== 'noaccess' && rights?.clients !== '') {
        temp.push(clients);
      }
      if (rights?.jobs !== 'noaccess' && rights?.jobs !== '') {
        temp.push(jobs);
      }
      if (rights?.resumes !== 'noaccess' && rights?.resumes !== '') {
        temp.push(resumes);
      }
      if (rights?.leads !== 'noaccess' && rights?.leads !== '') {
        temp.push(leads);
      }
      if (rights?.placements !== 'noaccess' && rights?.placements !== '') {
        temp.push(placement);
      }
      if (rights?.dashboard !== 'noaccess' && rights?.dashboard !== '') {
        temp.push(dashboard);
      }
      temp.push(setting);
      console.log('temp arry', temp);
      setNavConfig1(temp);
    } else if (role === 'salesTeamLead') {
      temp.push(salesManagement, salesworksheet);
      // setNavConfig1([salesManagement]);
      if (rights?.worksheet !== 'noaccess' && rights?.worksheet !== '') {
        temp.push(rworksheet);
      }
      if (rights?.clients !== 'noaccess' && rights?.clients !== '') {
        temp.push(clients);
      }
      if (rights?.jobs !== 'noaccess' && rights?.jobs !== '') {
        temp.push(jobs);
      }
      if (rights?.resumes !== 'noaccess' && rights?.resumes !== '') {
        temp.push(resumes);
      }
      if (rights?.leads !== 'noaccess' && rights?.leads !== '') {
        temp.push(leads);
      }
      if (rights?.placements !== 'noaccess' && rights?.placements !== '') {
        temp.push(placement);
      }

      if (rights?.dashboard !== 'noaccess' && rights?.dashboard !== '') {
        temp.push(dashboard);
      }
      temp.push(setting);
      console.log('temp arry', temp);
      setNavConfig1(temp);
    }
    console.log('test', navConfig1);
  }, [role]);

  const minbar = () => {
    setNavwidth(100);
  };

  const extendbar = () => {
    setNavwidth(200);
  };

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
      }}
    >
      {NAV_WIDTH === 100 ? (
        <Box sx={{ px: 2.5, pt: 3, mb: 2, display: 'inline-flex' }}>
          <Link to="/" sx={{ display: 'contents' }}>
            {/* <img alt="." src="/assets/faviocn.svg" /> */}
            <img alt="." src="/assets/logoFoxearchNew.svg" />
          </Link>
          <Stack
            sx={{
              mt: -2,
              width: 15,
              height: 15,
              border: 1,
              borderColor: 'text.secondary',
              borderRadius: 2,
              alignItems: 'center',
            }}
          >
            <Iconify
              icon="ic:round-greater-than"
              sx={{ color: 'text.secondary', width: 15, height: 15 }}
              style={{ cursor: 'pointer' }}
              onClick={() => extendbar()}
            />
          </Stack>
        </Box>
      ) : (
        <Box sx={{ pt: 3, display: 'inline-flex' }}>
          <Link to="/" sx={{ display: 'contents' }}>
            <img alt="." src="/assets/logoBlue.svg" width={'80%'} height={'80%'} style={{ marginLeft: '22px' }} />
          </Link>
          {/* <p style={{marginLeft:25, color :'red'}}>a</p> */}
          <Stack
            sx={{
              mt: -2,
              // ml: 1,           
              mr: 0.3,
              width: 20,
              height: 20,
              border: 1,
              borderColor: 'text.secondary',
              borderRadius: 2,
              alignItems: 'center',
            }}
          >
            <Iconify
              icon="ic:round-greater-than"
              sx={{ color: 'text.secondary', width: 20, height: 20 }}
              style={{ cursor: 'pointer' }}
              rotate={2}
              onClick={() => minbar()}
            />
          </Stack>
        </Box>
      )}

      {NAV_WIDTH === 100 ? (
        <Box sx={{ mx: 1 }}>
          <Link underline="none">
            <MinAccount>
              <Avatar src={account.photoURL} alt="photoURL" />

              <Box sx={{}}>
                <Typography variant="subtitle2" sx={{ color: 'text.primary', textAlign: 'center', mt: 0.5 }}>
                  {lRole === 'recruiterHead'
                    ? 'Recruitment Head'
                    : lRole === 'recruiterManager'
                      ? 'Recruitment Manager'
                      : lRole === 'recruiterTeamLead'
                        ? 'Recruitment Lead'
                        : lRole === 'recruiter'
                          ? 'Technical Recruiter'
                          : lRole === 'recruiterTrainee'
                            ? 'Recruitment Trainee'
                            : lRole.replace(/([A-Z])/g, ' $&') || '-'}
                </Typography>
              </Box>
            </MinAccount>
          </Link>
        </Box>
      ) : (
        <Box sx={{ mb: 1, mx: 1 }}>
          <Link underline="none">
            <StyledAccount>
              <Avatar src={account.photoURL} alt="photoURL" />
              <Box sx={{ ml: 2 }}>
                <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                  {/* {localStorage.getItem('username')} */}
                </Typography>
                <Typography variant="subtitle2" sx={{ color: 'text.secondary', mt: 0.5 }}>
                  {lRole === 'recruiterHead'
                    ? 'Recruitment Head'
                    : lRole === 'recruiterManager'
                      ? 'Recruitment Manager'
                      : lRole === 'recruiterTeamLead'
                        ? 'Recruitment Lead'
                        : lRole === 'recruiter'
                          ? 'Technical Recruiter'
                          : lRole === 'recruiterTrainee'
                            ? 'Recruitment Trainee'
                            : lRole.replace(/([A-Z])/g, ' $&') || '-'}
                </Typography>
              </Box>
            </StyledAccount>
          </Link>
        </Box>
      )}
      <NavSection data={navConfig1} navwd={NAV_WIDTH} />
    </Scrollbar>
  );

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV_WIDTH },
      }}
    >
      {isDesktop ? (
        <Drawer
          open
          variant="permanent"
          PaperProps={{
            sx: {
              width: NAV_WIDTH,
              bgcolor: 'background.default',
              borderRightStyle: 'dashed',
            },
          }}
        >
          {renderContent}
        </Drawer>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          ModalProps={{
            keepMounted: true,
          }}
          PaperProps={{
            sx: { width: NAV_WIDTH },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </Box>
  );
}
