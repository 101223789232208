import PropTypes from 'prop-types';
import ReactApexChart from 'react-apexcharts';
import { useTheme, styled } from '@mui/material/styles';
import { Card, CardHeader,Typography } from '@mui/material';
import { fNumber } from '../../../utils/formatNumber';
import { useChart } from '../../../components/chart';

const CHART_HEIGHT = 372;
const LEGEND_HEIGHT = 52;

const StyledChartWrapper = styled('div')(({ theme }) => ({
  height: CHART_HEIGHT,
  marginTop: theme.spacing(5),
  '& .apexcharts-canvas svg': { height: CHART_HEIGHT },
  '& .apexcharts-canvas svg,.apexcharts-canvas foreignObject': {
    overflow: 'visible',
  },
  '& .apexcharts-legend': {
    height: LEGEND_HEIGHT,
    alignContent: 'center',
    position: 'relative !important',
    borderTop: `solid 1px ${theme.palette.divider}`,
    top: `calc(${CHART_HEIGHT - LEGEND_HEIGHT}px) !important`,
  },
}));

const AppCurrentVisits = ({
  title,
  date,
  chartColors,
  chartData,
  centerLabel,  
  centerValue,
  valueStyles,
  ...other
}) => {
  const theme = useTheme();

  const chartLabels = chartData.map((item) => item.label);
  const chartSeries = chartData.map((item) => item.value);

  const chartOptions = useChart({
    colors: chartColors,
    labels: chartLabels,
    stroke: { colors: [theme.palette.background.paper] },
    legend: { floating: true, horizontalAlign: 'center' },
    dataLabels: {
      enabled: true,
      dropShadow: { enabled: false },
      formatter(val) {
        return fNumber(val);
      },
    },
    tooltip: {
      fillSeriesColor: false,
      y: {
        formatter(seriesName) {
          return fNumber(seriesName);
        },
        title: {
          formatter(seriesName) {
            return `${seriesName}`;
          },
        },
      },
    },
    plotOptions: {
      radialBar: {
        dataLabels: {
          value: {
            fontSize: '24px',
            formatter(val) {
              return fNumber(val);
            },
            // style: {
            //   colors: chartData.map((item) => {
            //     if (item.label === 'Prospects') return valueStyles[0];
            //     if (item.label === 'Submissions') return valueStyles[1];
            //     if (item.label === 'Interviews') return valueStyles[2];
            //     if (item.label === 'Placements') return valueStyles[3];
            //     if (item.label === 'Backouts') return valueStyles[4];
            //     return '#000000'; 
            //   }),   
            //   fontSize: '24px',
            // },
          },
          total: {
            show: true,
            label: centerLabel,
            formatter() {
              return centerValue;
            },
            color: '#2ECC71',
            fontSize: '18px',
            fontWeight: 'bold',
          },
        },
      },
    },
    
  });

  return (
    <Card {...other}>
      <CardHeader title={title} />
      <Typography 
        // variant="subtitle2" 
        color= "black"
        align="center" 
        sx={{ 
          mt: 1, 
          fontSize: '0.78rem',
          // fontWeight: 'bold' 
          marginTop:-0.4
        }}
      >
        {date}
      </Typography>
      <StyledChartWrapper dir="ltr"> 
        <ReactApexChart type="radialBar" series={chartSeries} options={chartOptions} height={350} />
      </StyledChartWrapper>
    </Card>
  );
};

AppCurrentVisits.propTypes = {
  title: PropTypes.string,
  date: PropTypes.any,
  chartColors: PropTypes.arrayOf(PropTypes.string),
  chartData: PropTypes.arrayOf(  
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
    })
  ),
  centerLabel: PropTypes.string,
  centerValue: PropTypes.number,
  // valueStyles: PropTypes.arrayOf(PropTypes.string),
};

export default AppCurrentVisits;
    