import { Navigate, useRoutes } from 'react-router-dom';
import './global.css';
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
import BlogPage from './pages/BlogPage';
import UserPage from './pages/UserPage';
import LoginPage from './pages/LoginPage';
import Page404 from './pages/Page404';
import ProductsPage from './pages/ProductsPage';
import DashboardAppPage from './pages/DashboardAppPage';
import ResumeDatabase from './pages/ResumeDatabase';
import Worksheet from './pages/Worksheet';
import SalesWorksheet from './pages/SalesWorksheet';
import TrackingSHeet from './pages/TrackingSHeet';
import UserManagement from './pages/UserManagement';
import CompanyManagement from './pages/CompanyManagement';
import RecruiterManagement from './pages/RecruiterManagement';
import SalesManagement from './pages/SalesManagement';
import ResumeDetail from './pages/ResumeDetail';
import RecruiterTeamLead from './pages/RecruiterTeamLead';
import SalesTeamLead from './pages/SalesTeamLead';
import Jobs from './pages/Jobs';
import Clients from './pages/Clients';
import AddJob from './pages/AddJob';
import History from './pages/History';
import SalesDetail from './pages/SalesDetail';
import AddCompany from './pages/addCompany';
import Admins from './pages/admins';
import Management from './pages/Management';
import RecruiterManager from './pages/RecruiterManager';
import SalesManager from './pages/SalesManager';
import SalesTrainee from './pages/Salestrainee';
import Recruitertrainee from './pages/Recruitertrainee';
import JobDetail from './pages/jobDetail';
import ClientDetail from './pages/clientDetail';
import ClientLeads from './pages/ClientLeads';
import Email from './pages/Email'; 
import MainDashboard from './pages/MainDashboard';
import Signature from './layouts/dashboard/header/Signature';
import MasterData from './pages/MasterData';
// ----------------------------------------------------------------------

export default function Router() {
  const routes = useRoutes([ 
    {path: '/login',element: <LoginPage />,},
    {
      path: '/',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/login" />, index: true },
        { path: 'app', element: <DashboardAppPage /> },
        { path: 'user', element: <UserPage /> },
        { path: 'products', element: <ProductsPage /> },
        { path: 'blog', element: <BlogPage /> },
        { path: 'resumedatabase', element: <ResumeDatabase /> },
        { path: 'worksheet', element: <Worksheet /> },
        { path: 'salesworksheet', element: <SalesWorksheet /> },
        { path: 'jobs', element: <Jobs /> },
        { path: 'clients', element: <Clients /> },
        { path: 'usermanagement', element: <UserManagement /> },
        { path: 'companymanegement', element: <CompanyManagement /> },
        { path: 'Signature', element: <Signature /> },
        {
          path: 'addjob',
          element: <AddJob />
        },
        { path: 'resumeDetail', element: <ResumeDetail /> },
        { path: 'salesManagement', element: <SalesManagement /> },
        { path: 'recruiterManagement', element: <RecruiterManagement /> },
        { path: 'recruiterTeamLead', element: <RecruiterTeamLead /> },
        { path: 'salesTeamLead', element: <SalesTeamLead /> },
        { path: 'salesDetail', element: <SalesDetail /> },
        {path:'history', element:<History/>},
        {path:'addCompany',element:<AddCompany/>},
        {path:'admins',element:<Admins/>},
        {path:'management',element:<Management/>},
        {path:'recuiterManager',element:<RecruiterManager/>},
        {path:'salesManager',element:<SalesManager/>},
        {path:'salesTrainee',element:<SalesTrainee/>},
        {path:'recruitertrainee',element:<Recruitertrainee/>},
        {path:'jobDetail',element:<JobDetail/>},
        {path:"clientDetail",element:<ClientDetail/>},
        {path:"clientLeads",element:<ClientLeads/>},
        {path: "email", element:<Email/>},
        {path:'mainDashboard', element:<MainDashboard/>},
        {path:'masterData', element:<MasterData/>},
        
      ],
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
